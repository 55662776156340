import React, { useState, useEffect } from "react";
import { parse } from "papaparse";
import { Paper, Typography, Button } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { API } from "../config/api";
import { withSwalInstance } from "sweetalert2-react";
import swal from "sweetalert2";
import True from "../icons/truere.png";
import False from "../icons/falsere.png";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function ImportCSV(props) {
  const [open, setOpen] = React.useState(false);
  const userId = localStorage.getItem("userId");
  const [matching, setMatch] = useState("");
  const [itung, setItung] = useState(0);
  const [loading, setLoading] = useState(false);
  const SweetAlert = withSwalInstance(swal);
  const [show, setShow] = useState(false);
  const [falseShow, setFalse] = useState(false);
  const [falseShows, setFalseIng] = useState(false);
  const [falseShowing, setFalseShow] = useState(false);
  const [dataBarcodes, setDataBarcodes] = useState([]);
  const classes = useStyles();
  const [alerts, setAlerts] = useState("");
  const [match, setMatchings] = useState([]);
  const [data, setData] = useState([]);
  const [hasils, setHasils] = useState("");
  const [highlight, setHighlight] = React.useState(false);
  const [contacts, setContacts] = React.useState([]);
  const [form, setForm] = React.useState({
    nama_file: "",
    tanggal_run: "",
    operator: "",
    mesin_id: "",
    internal: "",
    gen_e: "",
    gen_orf: "",
    result: "",
    keterangan: "",
  });

  let date = new Date();
  let detik = date.getSeconds();
  let detiks = detik > 9 ? detik : "0" + detik;
  let menit = date.getMinutes();
  let menits = menit > 9 ? menit : "0" + menit;
  let jam = date.getHours();
  let jams = jam > 9 ? jam : "0" + jam;

  let tanggal = 12;
  let tanggals = tanggal.length > 9 ? tanggal : "0" + tanggal;
  let bulan = date.getMonth() + 1;
  let bulans = bulan > 9 ? bulan : "0" + bulan;
  let tahun = date.getFullYear();
  let now =
    tahun +
    "-" +
    bulans +
    "-" +
    tanggals +
    " " +
    jams +
    ":" +
    menits +
    ":" +
    detiks;

  let nows = tahun + "-" + bulans + "-" + tanggals;

  const {
    nama_file,
    tanggal_run,
    operator,
    mesin_id,
    internal,
    gen_e,
    gen_orf,
    result,
    keterangan,
  } = form;

  let nama = "";
  var arraysMatch = [];

  useEffect(() => {
    const dataBarcode = async () => {
      const res = await API.get(`/barcode/instansi/${userId}`);
      console.log(res.data);
      setDataBarcodes(res.data.data);
    };
    dataBarcode();
  }, []);

  var i = 27;
  var t = 0;
  var y = 0;
  var x = 0;
  var z = 0;

  //name
  for (i; i < contacts.length; i++) {
    if (
      contacts[i][2] != "" &&
      contacts[i][2] != "Name" &&
      contacts[i][2] === contacts[i][2]
    ) {
      var names = contacts[i][2];
      t += 1;
      y = y + 1;

      if (t == 1) {
        var t1 = i;
      }
    }

    if (contacts[i][2] == "") {
      var m = 0;
    }
  }

  //internal
  for (i; i < contacts.length; i++) {
    if (
      contacts[i][4] != "" &&
      contacts[i][4] != "Ct" &&
      contacts[i][4] === contacts[i][2]
    ) {
      x += 1;
      z = z + 1;

      if (x == 1) {
        var x1 = i;
      }
    }

    if (contacts[i][2] == "") {
      var m = 0;
    }
  }

  var tz = Math.ceil((y - 1) / 3);
  var xz = 27 + tz + 5;
  var yz = xz + tz + 5;
  var arrayGenORF = [];
  var arrayInternal = [];
  var arrayGenE = [];
  var arraysNames = [];
  var arrayTesting = [];

  //inisialisasi Gen E
  for (i = xz; i < xz + tz; i++) {
    if (contacts[i][4] == "") {
      arrayGenE.push(0);
    } else {
      arrayGenE.push(
        contacts[i][4].substring(0, 2) + "." + contacts[i][4].substring(3, 5)
      );
    }
  }
  //inisialisasi Internal
  for (i = 27; i < t1 + tz; i++) {
    arrayInternal.push(
      contacts[i][4].substring(0, 2) + "." + contacts[i][4].substring(3, 5)
    );
    console.log(
      contacts[i][4].substring(0, 2) + "." + contacts[i][4].substring(3, 5)
    );
  }
  //inisialisasi nama
  for (i = 27; i < t1 + tz; i++) {
    arraysNames.push(contacts[i][2]);
  }

  const dataBarcodeMatch = async () => {
    console.log("Loading...");
    setOpen(true);
    for (let i = 0; i < arraysNames.length; i++) {
      const res = await API.get(`/barcodez/${userId}?nama=${arraysNames[i]}`);
      res.data == true
        ? arraysMatch.push("Match")
        : arraysMatch.push("Not Match");
      console.log(1);
    }
    setMatchings(arraysMatch);
    console.log(match);
    setOpen(false);
    setItung(arraysMatch.length);

    arraysMatch.map((item) => (item == "Match" ? arrayTesting.push("Ya") : ""));
    console.log("HEHEHEHE", arrayTesting);

    if (arraysNames.length - 2 == arrayTesting.length) {
      setAlerts("Match");
    } else {
      setAlerts("Not Match");
    }
    console.log("Nama", arraysNames.length - 2);
    console.log("Namass", arrayTesting.length);
  };

  console.log(alerts);

  useEffect(() => {
    dataBarcodeMatch();
  }, [arraysNames.length]);

  //genorf
  for (i = yz; i < yz + tz; i++) {
    if (contacts[i][4] == "") {
      arrayGenORF.push(0);
    } else {
      arrayGenORF.push(
        contacts[i][4].substring(0, 2) + "." + contacts[i][4].substring(3, 5)
      );
    }
  }

  const handleStore = async (jawaban) => {
    const jawabans = jawaban;
    try {
      setOpen(true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      let result;
      const form = new FormData();
      let i = 0;
      console.log(hasils);

      if (jawabans == "Benar") {
        for (i; i <= arraysNames.length - 1; i++) {
          form.append("nama_file_id", contacts[2][1]);
          form.append("tanggal_run", now);
          form.append("tanggal", nows);
          form.append("instansis_id", userId);
          form.append("operator", contacts[6][1]);
          form.append("mesin_id", contacts[9][1]);
          dataBarcodes.map((item) =>
            arraysNames[i] == "PC"
              ? form.append("nama_id", arraysNames[i] + " " + contacts[2][1])
              : arraysNames[i] == "NC"
              ? form.append("nama_id", arraysNames[i] + " " + contacts[2][1])
              : item.nama == arraysNames[i]
              ? form.append("nama_id", arraysNames[i])
              : ""
          );
          form.append("internal_control", arrayInternal[i]);
          if (arrayGenE[i] >= 37 && arrayGenORF[i] >= 37) {
            if (arraysNames[i] == "PC" || arraysNames[i] == "NC") {
              result = "";
              form.append("ct_gen_e", arrayGenE[i]);
              form.append("ct_orf", arrayGenORF[i]);
            } else {
              result = "Negatif";
              form.append("ct_gen_e", arrayGenE[i]);
              form.append("ct_orf", arrayGenORF[i]);
            }
          }
          if (arrayGenE[i] < 37 || arrayGenORF[i] < 37) {
            if (arraysNames[i] == "PC" || arraysNames[i] == "NC") {
              result = "";
              form.append("ct_gen_e", arrayGenE[i]);
              form.append("ct_orf", arrayGenORF[i]);
            } else {
              result = "Positif";
              form.append("ct_gen_e", arrayGenE[i]);
              form.append("ct_orf", arrayGenORF[i]);
            }
          }
          if (arrayGenE[i] < 37 && arrayGenORF[i] == 0) {
            if (arraysNames[i] == "PC" || arraysNames[i] == "NC") {
              result = "";
              form.append("ct_gen_e", arrayGenE[i]);
              form.append("ct_orf", 0);
            } else {
              result = "Positif";
              form.append("ct_gen_e", arrayGenE[i]);
              form.append("ct_orf", 0);
            }
          }
          if (arrayGenE[i] == 0 && arrayGenORF[i] < 37) {
            if (arraysNames[i] == "PC" || arraysNames[i] == "NC") {
              result = "";
              form.append("ct_gen_e", 0);
              form.append("ct_orf", arrayGenORF[i]);
            } else {
              result = "Positif";
              form.append("ct_gen_e", 0);
              form.append("ct_orf", arrayGenORF[i]);
            }
          }
          if (arrayGenE[i] == 0 && arrayGenORF[i] == 0) {
            if (arraysNames[i] == "PC" || arraysNames[i] == "NC") {
              result = "";
              form.append("ct_gen_e", 0);
              form.append("ct_orf", 0);
            } else {
              result = "Negatif";
              form.append("ct_gen_e", 0);
              form.append("ct_orf", 0);
            }
          }

          form.append("result", result);
          form.append("keterangan_details", "");

          await API.post(
            `/detail-hasil/${userId}?namabar=${arraysNames[i]}&namafile=${contacts[2][1]}`,
            form,
            config
          );
        }

        setOpen(false);
        setShow(true);
        contacts.length = 0;
        //window.location.reload();
      } else {
        setOpen(false);

        contacts.length = 0;
        //window.location.reload();
        setFalseShow(true);
      }
    } catch (err) {
      setFalseShow(true);
      setContacts([]);
    }
  };

  const hasilResult = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      // if (file !== "" && decision == "Gagal") {
      //   const form = new FormData();
      //   form.append("nama_file", file);
      //   form.append("ekstrasi", decision);
      //   form.append("tanggal_run", now);
      //   form.append("tanggal", nows);
      //   form.append("keterangan", "");
      //   form.append("instansi_id", userId);
      //   const res = await API.post(`/hasil/${userId}`, form, config);
      // }

      const form = new FormData();
      form.append("nama_file", contacts[2][1]);
      form.append("ekstrasi", "Sukses");
      form.append("tanggal_run", now);
      form.append("tanggal", nows);
      form.append("keterangan", "");
      form.append("instansi_id", userId);
      const res = await API.post(`/hasil/${userId}`, form, config);

      handleStore("Benar");
    } catch (err) {
      handleStore("Salah");
    }
  };

  let unit = "";
  let arraysUnit = [];
  let arrayzTest = [];
  let unitz = 0;
  let arrayUnit = [];
  let barcodeTest = [];

  for (let j = 0; j < arraysMatch.length; j++) {
    console.log("Test");
  }

  // for (i = 29; i < t1 + tz; i++) {
  //   dataBarcodes.map((item) =>
  //     item.instansi_id == userId
  //       ? item.nama !== contacts[i][2]
  //         ? ""
  //         : arrayzTest.push("Match")
  //       : ""
  //   );
  //   unitz = unitz + 1;
  // }

  // for (i = 29; i < t1 + tz; i++) {
  //   dataBarcodes.map((item) =>
  //     item.nama !== contacts[i][2] ? "" : arrayzTest.push("Match")
  //   );
  //   unitz = unitz + 1;
  // }

  return (
    <div>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <SweetAlert
        show={show}
        title="Berhasil Upload!"
        imageUrl={True}
        text="Data Berhasil DItambahkan"
        onConfirm={() => setShow(false)}
      />

      <SweetAlert
        show={falseShow}
        title="Gagal Upload!"
        imageUrl={False}
        text="Terdapat Kolom Nama Yang Tidak Matching, Selain NC PC, Mohon Diperbaiki!"
        onConfirm={() => setFalse(false)}
      />

      <SweetAlert
        show={falseShowing}
        title="Gagal Upload!"
        imageUrl={False}
        text="Nama File Sudah Terdaftar!"
        onConfirm={() => setFalse(false)}
      />

      <SweetAlert
        show={falseShows}
        title="Gagal Upload!"
        imageUrl={False}
        text="Masukkan File Terlebih Dahulu!"
        onConfirm={() => setFalseIng(false)}
      />

      <div
        style={{
          width: "60%",
          margin: "0 auto",
        }}
      >
        <h1 style={{ textAlign: "center" }}>Import CSV</h1>
        <Paper
          elevation={2}
          square
          style={
            highlight
              ? { padding: 40, backgroundColor: "#bdc3c7" }
              : { padding: 40 }
          }
          onDragEnter={() => {
            setHighlight(true);
          }}
          onDragLeave={() => {
            setHighlight(false);
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDrop={(e) => {
            e.preventDefault();
            console.log(e.dataTransfer.files);
            Array.from(e.dataTransfer.files)
              .filter((file) => file.type === "application/vnd.ms-excel")
              .forEach(async (file) => {
                const text = await file.text();
                const result = parse(text);
                setContacts([...result.data]);
                console.log(result.data);
              });
          }}
        >
          <Typography style={{ textAlign: "center" }}>
            Drop File Here
          </Typography>
        </Paper>

        <div style={{ marginTop: 20 }}></div>
      </div>

      {loading ? (
        ""
      ) : (
        <form onSubmit={(e) => hasilResult(e)}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>No</StyledTableCell>
                  <StyledTableCell align="center">Nama</StyledTableCell>
                  <StyledTableCell align="center">Internal</StyledTableCell>
                  <StyledTableCell align="center">Gen E</StyledTableCell>
                  <StyledTableCell align="center">Gen ORF</StyledTableCell>
                  <StyledTableCell align="center">Operator</StyledTableCell>
                  <StyledTableCell align="center">Mesin ID</StyledTableCell>
                  <StyledTableCell align="center">Date</StyledTableCell>
                  <StyledTableCell align="center">Matching</StyledTableCell>
                  <StyledTableCell align="center">Tanggal Run</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {arraysNames.map((items, index) => (
                  <StyledTableRow>
                    <StyledTableCell align="center">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">{items}</StyledTableCell>
                    <StyledTableCell align="center">
                      {arrayInternal[index]}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {arrayGenE[index] == 0 ? "" : arrayGenE[index]}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {arrayGenORF[index] == 0 ? "" : arrayGenORF[index]}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {contacts[6][1]}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {contacts[9][1]}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {contacts[3][1]}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      {match.length > 0 ? (
                        match[index] == "Match" ? (
                          <p style={{ color: "green", fontWeight: "bold" }}>
                            Match
                          </p>
                        ) : (
                          <p style={{ color: "red", fontWeight: "bold" }}>
                            Not Match
                          </p>
                        )
                      ) : (
                        ""
                      )}
                    </StyledTableCell>

                    <StyledTableCell align="center">{now}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div
            style={{
              marginTop: 50,
              marginBottom: 50,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {alerts == "Not Match" ? (
              <Button
                variant="contained"
                color="secondary"
                style={{ width: 560 }}
                onClick={() => setFalse(true)}
              >
                Upload
              </Button>
            ) : contacts.length > 0 ? (
              <Button
                style={{ width: 560 }}
                variant="contained"
                color="primary"
                type="submit"
              >
                Upload
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                style={{ width: 560 }}
                onClick={() => setFalseIng(true)}
              >
                Upload
              </Button>
            )}
          </div>
        </form>
      )}
    </div>
  );
}

export default ImportCSV;
