import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button, TextField } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { withSwalInstance } from "sweetalert2-react";
import swal from "sweetalert2";
import True from "../icons/truere.png";
import { API } from "../config/api";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    width: 640,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function ModalInput(props) {
  const SweetAlert = withSwalInstance(swal);
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [keterangan, setKeterangan] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setKeterangan(e.target.value);
  };

  const handleStore = async (e) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({ keterangan: keterangan });
      const res = await API.put(`/hasil/${props.id}`, body, config);
      setOpen(false);
      setShow(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(keterangan);
  }, [keterangan]);

  console.log("id:", props.id);

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        id="transition-modal-description"
        onClick={handleOpen}
      >
        Edit
      </Button>
      <SweetAlert
        show={show}
        title="Berhasil Daftar!"
        imageUrl={True}
        text="Data Berhasil DItambahkan"
        onConfirm={() => setShow(false)}
      />
    </div>
  );
}
