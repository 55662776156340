import React, { useEffect, useState } from "react";
import { TextField, Grid, Button, Paper, Container } from "@material-ui/core";
import { API } from "../config/api";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withSwalInstance } from "sweetalert2-react";
import swal from "sweetalert2";
import True from "../icons/truere.png";
import { Link } from "react-router-dom";
import { Style } from "@material-ui/icons";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function TambahInstansi(props) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [form, setFormData] = useState({
    nama: "",
    alamat: "",
    telpon: "",
    fax: "",
    dokter: "",
  });

  const SweetAlert = withSwalInstance(swal);
  const { nama, alamat, telpon, fax, dokter } = form;

  const handleChange = (e) => {
    setFormData({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    console.log(form);
  }, [form]);

  const loadData = async () => {
    const res = await API.get("/instansi");
    setData(res.data.data);
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleStore = async (e) => {
    e.preventDefault();
    try {
      console.log("BErhasil");
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const form = new FormData();
      form.append("nama", nama);
      form.append("alamat", alamat);
      form.append("telpon", telpon);
      form.append("fax", fax);
      form.append("nama_dokter", dokter);
      form.append("active", "Aktif");

      await API.post("/instansi", form, config);

      const res = await API.get("/instansi");

      setData(res.data.data);

      setFormData({
        nama: "",
        alamat: "",
        telpon: "",
        fax: "",
        dokter: "",
      });

      setShow(true);
    } catch (error) {}
  };

  const classes = useStyles();

  const handleDisable = async (id, active) => {
    try {
      console.log(id);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      if (active == "Aktif") {
        const body = JSON.stringify({ active: "Tidak Aktif" });
        await API.put(`/instansi/${id}`, body, config);
      } else {
        const body = JSON.stringify({ active: "Aktif" });
        await API.put(`/instansi/${id}`, body, config);
      }

      console.log("Berhasil");
      loadData();
    } catch (error) {}
  };

  return (
    <div>
      <SweetAlert
        show={show}
        title="Berhasil Menambahkan Data!"
        imageUrl={True}
        text="Data Berhasil Ditambahkan"
        onConfirm={() => setShow(false)}
      />

      <div style={{ maxWidth: 840, margin: "0 auto" }}>
        <Paper>
          <Container style={{ paddingTop: 20, paddingBottom: 20 }}>
            <form onSubmit={(e) => handleStore(e)}>
              <div style={{ margin: 10 }}></div>
              <Grid xs={12}>
                <TextField
                  required={true}
                  style={{ width: "98%" }}
                  id="standard-textarea"
                  onChange={(e) => handleChange(e)}
                  name="nama"
                  value={nama}
                  label="Nama Instansi"
                  placeholder="Mis. RSUD Kota Tangerang"
                  multiline
                />
              </Grid>
              <div style={{ margin: 10 }}></div>

              <Grid xs={12}>
                <TextField
                  required={true}
                  style={{ width: "98%" }}
                  id="standard-textarea"
                  onChange={(e) => handleChange(e)}
                  name="dokter"
                  value={dokter}
                  label="Nama Dokter"
                  placeholder="Masukkan Nama Dokter"
                  multiline
                />
              </Grid>

              <div style={{ margin: 10 }}></div>

              <Grid xs={12}>
                <TextField
                  required={true}
                  style={{ width: "98%" }}
                  id="standard-textarea"
                  onChange={(e) => handleChange(e)}
                  name="alamat"
                  value={alamat}
                  label="Alamat Instansi"
                  placeholder="Alamat Instansi"
                  multiline
                />
              </Grid>

              <div style={{ margin: 10 }}></div>

              <Grid xs={12}>
                <TextField
                  style={{ width: "98%" }}
                  id="standard-number"
                  required={true}
                  label="Telpon"
                  onChange={(e) => handleChange(e)}
                  name="telpon"
                  value={telpon}
                  type="number"
                  placeholder="Nomor Telpon"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <div style={{ margin: 10 }}></div>

              <Grid xs={12}>
                <TextField
                  style={{ width: "98%" }}
                  id="standard-number"
                  required={true}
                  label="Fax"
                  placeholder="Mis. 021 32939493"
                  onChange={(e) => handleChange(e)}
                  name="fax"
                  value={fax}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <div style={{ margin: 10 }}></div>
              <div style={{ marginTop: 25 }}></div>
              <Button
                style={{ width: "100%" }}
                variant="contained"
                color="primary"
                type="submit"
              >
                Tambahkan
              </Button>
            </form>
          </Container>
        </Paper>
      </div>

      <div style={{ marginTop: 30 }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">No</StyledTableCell>
                <StyledTableCell align="center">Nama</StyledTableCell>
                <StyledTableCell align="center">Alamat</StyledTableCell>
                <StyledTableCell align="center">No. Telpon</StyledTableCell>
                <StyledTableCell align="center">Fax</StyledTableCell>
                <StyledTableCell align="center">Nama Dokter</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <StyledTableRow>
                  <StyledTableCell align="center">{index + 1}</StyledTableCell>
                  <StyledTableCell align="left">{item.nama}</StyledTableCell>
                  <StyledTableCell align="left">{item.alamat}</StyledTableCell>
                  <StyledTableCell align="center">
                    {item.telpon}
                  </StyledTableCell>
                  <StyledTableCell align="center">{item.fax}</StyledTableCell>
                  <StyledTableCell align="center">
                    {item.nama_dokter}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.active}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Link to={`/update-instansi/${item.id}`}>
                      <Button
                        style={{ marginRight: 5 }}
                        variant="contained"
                        color="primary"
                      >
                        Edit
                      </Button>
                    </Link>

                    <Button
                      style={{ marginLeft: 5 }}
                      variant="contained"
                      type="submit"
                      style={
                        item.active == "Aktif"
                          ? { backgroundColor: "#ee5253", color: "white" }
                          : { backgroundColor: "#10ac84", color: "white" }
                      }
                      onClick={() => handleDisable(item.id, item.active)}
                    >
                      {item.active == "Aktif" ? "Disable" : "Enable"}
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default TambahInstansi;
