import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  Grid,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@material-ui/core";
import { API } from "../config/api";
import { useParams, Link } from "react-router-dom";

import Modal from "@material-ui/core/Modal";

import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { withSwalInstance } from "sweetalert2-react";
import swal from "sweetalert2";
import True from "../icons/truere.png";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3f51b5",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    width: 640,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function HasilTest() {
  let date = new Date();

  let { id } = useParams();
  const [instansi, setInstansi] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(1);
  const [keterangan, setKeterangan] = useState("");
  const classes = useStyles();
  const [form, setFormData] = useState({
    instansi_id: "",
  });
  const SweetAlert = withSwalInstance(swal);
  const [show, setShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const userId = localStorage.getItem("userId");
  const handleOpen = () => {
    setOpen(true);
  };

  const { instansi_id } = form;
  const role = localStorage.getItem("role");

  useEffect(() => {
    const loadDetail = async () => {
      setLoading(true);
      const res = await API.get(`/hasil/${id}`);
      setKeterangan(res.data.map((item) => item.keterangan));
      setLoading(false);
    };
    loadDetail();
  }, [id]);

  const handleClose = () => {
    setOpen(false);
    setKeterangan("");
  };

  const previous = () => {
    setPage(page <= 1 ? page : page - 1);
  };

  const next = () => {
    setPage(page === total ? total : page + 1);
  };

  const handleChangez = (e) => {
    e.target.type === "date"
      ? setFormData({
          ...form,
          [e.target.name]: e.target.value,
        })
      : setFormData({
          ...form,
          [e.target.name]: e.target.value,
        });
  };

  useEffect(() => {
    const loadData = async () => {
      //setData([]);
      const res = await API.get(
        `/hasil/instansi/${userId}?page=${page}&instansi=${instansi_id}`
      );
      setTotal(Math.ceil(res.data.data.total / res.data.data.per_page));
      setData(res.data.data.data);
    };
    loadData();
  }, [page, instansi_id]);

  const handleChange = (e) => {
    setKeterangan(e.target.value);
  };

  useEffect(() => {
    console.log(keterangan);
  }, [keterangan]);

  useEffect(() => {
    const loadInstansi = async () => {
      const res = await API.get("/instansi");
      setInstansi(res.data.data);
    };
    loadInstansi();
  }, []);

  const handleStore = async (e) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({ keterangan: keterangan });
      const res = await API.put(`/hasil/${id}`, body, config);

      setData(res.data.data);

      setShow(true);
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 style={{ textAlign: "center" }} id="transition-modal-title">
              Ubah Keterangan
            </h2>
            <form>
              {data.map((item) =>
                item.id == id ? (
                  <div>
                    <TextField
                      required={true}
                      style={{ width: "98%" }}
                      id="standard-textarea"
                      name="keterangan"
                      style={{ width: "100%" }}
                      value={keterangan}
                      onChange={(e) => handleChange(e)}
                      label="Keterangan"
                      placeholder="Keterangan"
                      multiline
                    />
                  </div>
                ) : (
                  ""
                )
              )}
            </form>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: 10, width: "100%" }}
              onClick={(e) => handleStore(e)}
            >
              Ubah Data
            </Button>
          </div>
        </Fade>
      </Modal>
      <TableContainer component={Paper}>
        <div style={{ margin: 10 }}>
          <Grid container xs={12}>
            <Button
              style={{ margin: 10 }}
              variant="contained"
              color="secondary"
              onClick={previous}
            >
              Previous
            </Button>
            <h2>
              {page} / {total ? total : 1}
            </h2>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: 10 }}
              onClick={next}
            >
              Next
            </Button>

            {role == "Admin" ? (
              <Grid sm={2}>
                <FormControl
                  style={{ marginLeft: 30, marginTop: 5, width: "98%" }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nama Instansi
                  </InputLabel>
                  <Select
                    required={true}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={instansi_id}
                    onChange={(e) => handleChangez(e)}
                    name="instansi_id"
                  >
                    {instansi.map((item) => (
                      <MenuItem value={item.id}>{item.nama}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </div>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Tanggal RUN</StyledTableCell>
              <StyledTableCell align="center">Nama File</StyledTableCell>
              <StyledTableCell align="center">Ekstrasi</StyledTableCell>
              <StyledTableCell align="center">Keterangan</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>

          <SweetAlert
            show={show}
            title="Berhasil Ubah!"
            imageUrl={True}
            text="Data Berhasil Diubah"
            onConfirm={() => setShow(false)}
          />

          <TableBody>
            {data.map((row) =>
              role == "User" && row.instansi_id == userId ? (
                <StyledTableRow key={row.id}>
                  <StyledTableCell align="center" component="th" scope="row">
                    {row.tanggal_run}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Button variant="contained">
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={`/hasil-detail/all/${row.nama_file}`}
                      >
                        {row.nama_file}
                      </Link>
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.ekstrasi}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.keterangan}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/hasil-test/${row.id}`}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        id="transition-modal-description"
                        onClick={() => handleOpen(row.id)}
                      >
                        Edit
                      </Button>
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              ) : role == "Admin" ? (
                <StyledTableRow key={row.id}>
                  <StyledTableCell align="center" component="th" scope="row">
                    {row.tanggal_run}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Button variant="contained">
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={`/hasil-detail/all/${row.nama_file}`}
                      >
                        {row.nama_file}
                      </Link>
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.ekstrasi}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.keterangan}
                  </StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </StyledTableRow>
              ) : (
                ""
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
