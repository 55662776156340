import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { InputData } from "./Pages/";
import { DrawerNavigation } from "./components/";
import { Login } from "./Pages/";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { LocalGasStationOutlined } from "@material-ui/icons";

function App() {
  const user = localStorage.getItem("user");
  console.log("USER INI", user);

  return (
    <Router>
      <div>{user == null ? <Login /> : <DrawerNavigation />}</div>
    </Router>
  );
}

export default App;
