import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Button, Grid } from "@material-ui/core";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import CropFreeOutlinedIcon from "@material-ui/icons/CropFreeOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import PersonIcon from "@material-ui/icons/Person";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import HomeIcon from "@material-ui/icons/Home";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

import ListItemText from "@material-ui/core/ListItemText";

import {
  Operator,
  UpdateInstansi,
  InputData,
  TambahInstansi,
  Hasil,
  Barcode,
  AddBarcode,
  ProfileDetail,
  ImportCSV,
  HasilTest,
  DetailHasil,
  GeneratePDF,
  Importer,
  PrintBarcode,
  Home,
} from "../Pages/";

import { Switch, Route, Link, useHistory } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const role = localStorage.getItem("role");

const routes = [
  {
    path: "/",
    exact: true,
    sidebar: () => <div>home!</div>,
    main: () => <Home />,
  },
  {
    path: "/hasil",
    sidebar: () => <div>bubblegum!</div>,
    main: () => <Hasil />,
  },
  {
    path: "/barcode",
    sidebar: () => <div>bubblegum!</div>,
    main: () => <Barcode />,
  },
  {
    path: "/profile/:id",
    sidebar: () => <div>bubblegum!</div>,
    main: () => <ProfileDetail />,
  },
  {
    path: "/add-barcode/:id",
    sidebar: () => <div>bubblegum!</div>,
    main: () => <AddBarcode />,
  },
  {
    path: "/inputdata",
    sidebar: () => <div>shoelaces!</div>,
    main: () => <InputData />,
  },
  {
    path: "/import-csv",
    sidebar: () => <div>shoelaces!</div>,
    main: () => <ImportCSV />,
  },
  {
    path: "/importer",
    sidebar: () => <div>shoelaces!</div>,
    main: () => <Importer />,
  },
  {
    path: "/add-instansi",
    sidebar: () => <div>shoelaces!</div>,
    main: () => <TambahInstansi />,
  },
  {
    path: "/hasil-test/:id",
    sidebar: () => <div>shoelaces!</div>,
    main: () => <HasilTest />,
  },
  {
    path: "/generate-pdf/:id",
    sidebar: () => <div>shoelaces!</div>,
    main: () => <GeneratePDF />,
  },
  {
    path: "/hasil-detail/:id",
    sidebar: () => <div>shoelaces!</div>,
    main: () => <DetailHasil />,
  },
  {
    path: "/barcode-print/:id",
    sidebar: () => <div>home!</div>,
    main: () => <PrintBarcode />,
  },
  {
    path: "/update-instansi/:id",
    sidebar: () => <div>home!</div>,
    main: () => <UpdateInstansi />,
  },
  {
    path: "/operator",
    sidebar: () => <div>home!</div>,
    main: () => <Operator />,
  },
];

export default function DrawerNavigation() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("userId");
    window.location.reload();
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Grid sm={11}>
            <Typography variant="h6" noWrap>
              Pendataan Hasil Test PCR
            </Typography>
          </Grid>

          <Grid sm={1}>
            <Button onClick={() => handleLogout()}>
              <Link style={{ textDecoration: "none" }} to="/">
                <Typography
                  style={{ textAlign: "center", color: "white" }}
                  variant="h6"
                  noWrap
                >
                  Logout
                </Typography>
              </Link>
            </Button>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />

        <List>
          <Link to="/" style={{ color: "grey", textDecoration: "none" }}>
            <ListItem button>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
          </Link>
        </List>

        {role == "User" ? (
          <List>
            <Link
              to="/inputdata"
              style={{ color: "grey", textDecoration: "none" }}
            >
              <ListItem button>
                <ListItemIcon>
                  <AddBoxOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Registrasi Pasien" />
              </ListItem>
            </Link>
          </List>
        ) : (
          ""
        )}

        <List>
          <Link to="/hasil" style={{ color: "grey", textDecoration: "none" }}>
            <ListItem button>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="Daftar Pasien" />
            </ListItem>
          </Link>
        </List>

        <List>
          <Link to="/barcode" style={{ color: "grey", textDecoration: "none" }}>
            <ListItem button>
              <ListItemIcon>
                <CropFreeOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Generate Barcode" />
            </ListItem>
          </Link>
        </List>

        {role == "User" ? (
          <List>
            <Link
              to="/import-csv"
              style={{ color: "grey", textDecoration: "none" }}
            >
              <ListItem button>
                <ListItemIcon>
                  <ImportExportIcon />
                </ListItemIcon>
                <ListItemText primary="Import CSV" />
              </ListItem>
            </Link>
          </List>
        ) : (
          ""
        )}

        <List>
          <Link
            to="/hasil-test/all"
            style={{ color: "grey", textDecoration: "none" }}
          >
            <ListItem button>
              <ListItemIcon>
                <ListAltOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Hasil Ekstrasi" />
            </ListItem>
          </Link>
        </List>

        <List>
          <Link
            to="/hasil-detail/all"
            style={{ color: "grey", textDecoration: "none" }}
          >
            <ListItem button>
              <ListItemIcon>
                <DataUsageIcon />
              </ListItemIcon>
              <ListItemText primary="Hasil Test" />
            </ListItem>
          </Link>
        </List>
        {role == "Admin" ? (
          <List>
            <Link
              to="/add-instansi"
              style={{ color: "grey", textDecoration: "none" }}
            >
              <ListItem button>
                <ListItemIcon>
                  <AccountBalanceIcon />
                </ListItemIcon>
                <ListItemText primary="Tambah Instansi" />
              </ListItem>
            </Link>
          </List>
        ) : (
          ""
        )}

        {role == "Admin" ? (
          <List>
            <Link
              to="/operator"
              style={{ color: "grey", textDecoration: "none" }}
            >
              <ListItem button>
                <ListItemIcon>
                  <SupervisorAccountIcon />
                </ListItemIcon>
                <ListItemText primary="Operator" />
              </ListItem>
            </Link>
          </List>
        ) : (
          ""
        )}

        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Switch>
          {routes.map((route, index) => (
            // Render more <Route>s with the same paths as
            // above, but different components this time.
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              children={<route.main />}
            />
          ))}
        </Switch>
      </main>
    </div>
  );
}
