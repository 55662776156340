import axios from "axios";

// create base url from axios

export const API = axios.create({
  baseURL: "http://jesemsolusi.com/csvconverter-api/api",
});

// export const API = axios.create({
//   baseURL: "http://localhost:8000/api",
// });

//city API
export const CityAPI = axios.create({
  baseURL: "https://dev.farizdotid.com/api",
});
