import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { API } from "../config/api";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function Barcode() {
  const role = localStorage.getItem("role");
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [datas, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const previous = () => {
    setPage(page <= 1 ? page : page - 1);
  };

  const next = () => {
    setPage(page === total ? total : page + 1);
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const res = await API.get(`/barcode/instansi/${userId}?page=${page}`);
      setTotal(Math.ceil(res.data.total / res.data.per_page));
      setData(res.data.data);
      setLoading(false);
    };
    loadData();
    console.log("TAS", datas);
  }, [page]);

  const loop = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];

  const number = 1;

  if (isLoading) {
    return (
      <TableContainer component={Paper}>
        <div style={{ margin: 10 }}>
          {role == "User" ? (
            <Link style={{ textDecoration: "none" }} to="add-barcode/all">
              <Button variant="contained" color="primary">
                Tambah Barcode
              </Button>
            </Link>
          ) : (
            ""
          )}
        </div>

        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ fontWeight: "bold" }}>
                ID Barcode
              </TableCell>
              <TableCell align="center" style={{ fontWeight: "bold" }}>
                Nama
              </TableCell>
              <TableCell align="center" style={{ fontWeight: "bold" }}>
                NIK
              </TableCell>
              <TableCell align="center" style={{ fontWeight: "bold" }}>
                Posisi Tabung
              </TableCell>
              <TableCell align="center" style={{ fontWeight: "bold" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loop.map((item) => (
              <TableRow>
                <TableCell align="center" component="th" scope="row">
                  <Skeleton height={20} />
                </TableCell>
                <TableCell align="center">
                  <Skeleton height={20} />
                </TableCell>
                <TableCell align="center">
                  <Skeleton height={20} />
                </TableCell>
                <TableCell align="center">
                  <Skeleton height={20} />
                </TableCell>
                <TableCell align="center">
                  <Skeleton height={20} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Grid xs={12} container>
          <Button
            style={{ margin: 10 }}
            variant="contained"
            color="secondary"
            onClick={previous}
          >
            Previous
          </Button>
          <h2>
            {page} / {total ? total : 1}
          </h2>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 10 }}
            onClick={next}
          >
            Next
          </Button>
        </Grid>
      </TableContainer>
    );
  }

  const userId = localStorage.getItem("userId");

  return (
    <TableContainer component={Paper}>
      <div style={{ margin: 10 }}>
        <Link style={{ textDecoration: "none" }} to="add-barcode/all">
          <Button variant="contained" color="primary">
            Tambah Barcode
          </Button>
        </Link>
      </div>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              ID Barcode
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Nama
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              NIK
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Posisi Tabung
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datas.map((item) =>
            role == "User" ? (
              <TableRow key={item.id}>
                <TableCell align="center" component="th" scope="row">
                  {item.id ? item.id : "-"}
                </TableCell>
                <TableCell align="left">
                  <Typography style={{ fontWeight: "bold" }}>
                    {item.nama ? item.nama : "-"}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {item.nik ? item.nik : "-"}
                </TableCell>
                <TableCell align="center">
                  {item.posisi_tabung ? item.posisi_tabung : "-"}
                </TableCell>
                <TableCell align="center">
                  {role == "User" ? (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/barcode-print/${item.id}`}
                    >
                      <Button variant="contained" color="primary">
                        Print Barcode
                      </Button>
                    </Link>
                  ) : (
                    ""
                  )}
                </TableCell>
              </TableRow>
            ) : role == "Admin" ? (
              <TableRow key={item.id}>
                <TableCell align="center" component="th" scope="row">
                  {item.id ? item.id : "-"}
                </TableCell>
                <TableCell align="left">
                  <Typography style={{ fontWeight: "bold" }}>
                    {item.nama ? item.nama : "-"}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {item.nik ? item.nik : "-"}
                </TableCell>
                <TableCell align="center">
                  {item.posisi_tabung ? item.posisi_tabung : "-"}
                </TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            ) : (
              ""
            )
          )}
        </TableBody>
      </Table>
      <Grid xs={12} container>
        <Button
          style={{ margin: 10 }}
          variant="contained"
          color="secondary"
          onClick={previous}
        >
          Previous
        </Button>
        <h2>
          {page} / {total ? total : 1}
        </h2>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: 10 }}
          onClick={next}
        >
          Next
        </Button>
      </Grid>
    </TableContainer>
  );
}
