import React, { useState, useEffect } from "react";
import "./style.css";
import {
  Paper,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Button,
  FormHelperText,
  Select,
  Grid,
  Typography,
} from "@material-ui/core";
import { withSwalInstance } from "sweetalert2-react";
import swal from "sweetalert2";
import True from "../icons/truere.png";
import False from "../icons/falsere.png";
import { useParams, useHistory } from "react-router-dom";
import { API } from "../config/api";
import { Repeat } from "@material-ui/icons";

function AddBarcode(props) {
  const history = useHistory();
  const { id } = useParams();
  const [form, setFormData] = useState({
    nama: "",
    nik: "",
    tabung: "",
    pasien: "",
  });
  const SweetAlert = withSwalInstance(swal);
  const userId = localStorage.getItem("userId");
  const [show, setShow] = useState(false);
  const [falseShow, setFalse] = useState(false);

  const [datas, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useState(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const res = await API.get(`/profile/instansi/${userId}`);
        const hasil = res.data;
        console.log("hasil", hasil);
        setData(hasil);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    loadData();
  }, []);

  const { nama, nik, tabung, pasien } = form;

  const handleChange = (e) => {
    setFormData({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    console.log(form);
  }, [form]);

  console.log(pasien);
  let date = new Date();
  let hari = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
  let bulan = date.getMonth();
  let bulans = bulan + 1;
  let bulansz = bulans > 9 ? bulans : "0" + bulans;
  let tahun = date.getFullYear().toString();
  let combo = hari + bulansz + tahun;
  let niks = form.pasien.substring(0, 16);
  let comboTanggal =
    form.pasien.substring(25, 27) +
    form.pasien.substring(22, 24) +
    form.pasien.substring(17, 21);
  console.log("COMVO", comboTanggal);
  let combine = niks + "." + comboTanggal + "." + combo + "." + form.tabung;

  let idPasien = form.pasien.substring(
    form.pasien.length - 2,
    form.pasien.length
  );

  const handleStore = async (e) => {
    e.preventDefault(e);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const form = new FormData();

      form.append("nama", combine);
      form.append("nik", niks);
      form.append("posisi_tabung", tabung);
      form.append("profile_user_id", niks);
      form.append("instansi_id", userId);

      const res = await API.post(`/barcode/${userId}`, form, config);
      setShow(true);
      setFormData({
        nama: "",
        nik: "",
        tabung: "",
        pasien: "",
      });

      history.push(`/profile/${niks}`);
    } catch (err) {
      setFalse(true);
    }
  };

  return (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <Paper elevation={3} style={{ width: 560 }} square>
        <form onSubmit={(e) => handleStore(e)}>
          <div style={{ padding: 20 }}>
            <Grid container xs={12}>
              <Grid xs={2}>
                <Typography style={{ fontWeight: "bold" }}>Code PCR</Typography>
              </Grid>
              <Grid xs={10}>
                <Typography>
                  {form.pasien === "" ? (
                    ""
                  ) : form.tabung === "" ? (
                    ""
                  ) : (
                    <>{combine}</>
                  )}
                </Typography>
              </Grid>
            </Grid>
            <div style={{ margin: 15 }}></div>
            <Grid container xs={12}>
              <Grid xs={2}>
                <Typography style={{ fontWeight: "bold" }}>NIK</Typography>
              </Grid>
              <Grid xs={10}>
                <Typography>{niks}</Typography>
              </Grid>
            </Grid>
            <div style={{ margin: 15 }}></div>
            <Grid xs={12}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Nama Pasien
                </InputLabel>
                <Select
                  required={true}
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={pasien}
                  onChange={(e) => handleChange(e)}
                  name="pasien"
                >
                  {datas.map((item) =>
                    id == item.id ? (
                      <MenuItem value={`${item.nik}.${item.tanggal_lahir}`}>
                        {item.nik} - {item.fullname}
                      </MenuItem>
                    ) : id == "all" ? (
                      <MenuItem value={`${item.nik}.${item.tanggal_lahir}`}>
                        {item.nik} - {item.fullname}
                      </MenuItem>
                    ) : (
                      ""
                    )
                  )}
                </Select>
                <FormHelperText>Some important helper text</FormHelperText>
              </FormControl>
            </Grid>
            <div style={{ margin: 15 }}></div>
            <Grid xs={12}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Posisi Tabung
                </InputLabel>
                <Select
                  required={true}
                  onChange={(e) => handleChange(e)}
                  value={tabung}
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  name="tabung"
                >
                  <MenuItem value="A1">A1</MenuItem>
                  <MenuItem value="A2">A2</MenuItem>
                  <MenuItem value="A3">A3</MenuItem>
                  <MenuItem value="A4">A4</MenuItem>
                  <MenuItem value="A5">A5</MenuItem>
                  <MenuItem value="A6">A6</MenuItem>
                  <MenuItem value="A7">A7</MenuItem>
                  <MenuItem value="A8">A8</MenuItem>
                  <MenuItem value="A9">A9</MenuItem>
                  <MenuItem value="A10">A10</MenuItem>
                  <MenuItem value="A11">A11</MenuItem>
                  <MenuItem value="A12">A12</MenuItem>

                  <MenuItem value="B1">B1</MenuItem>
                  <MenuItem value="B2">B2</MenuItem>
                  <MenuItem value="B3">B3</MenuItem>
                  <MenuItem value="B4">B4</MenuItem>
                  <MenuItem value="B5">B5</MenuItem>
                  <MenuItem value="B6">B6</MenuItem>
                  <MenuItem value="B7">B7</MenuItem>
                  <MenuItem value="B8">B8</MenuItem>
                  <MenuItem value="B9">B9</MenuItem>
                  <MenuItem value="B10">B10</MenuItem>
                  <MenuItem value="B11">B11</MenuItem>
                  <MenuItem value="B12">B12</MenuItem>

                  <MenuItem value="C1">C1</MenuItem>
                  <MenuItem value="C2">C2</MenuItem>
                  <MenuItem value="C3">C3</MenuItem>
                  <MenuItem value="C4">C4</MenuItem>
                  <MenuItem value="C5">C5</MenuItem>
                  <MenuItem value="C6">C6</MenuItem>
                  <MenuItem value="C7">C7</MenuItem>
                  <MenuItem value="C8">C8</MenuItem>
                  <MenuItem value="C9">C9</MenuItem>
                  <MenuItem value="C10">C10</MenuItem>
                  <MenuItem value="C11">C11</MenuItem>
                  <MenuItem value="C12">C12</MenuItem>

                  <MenuItem value="D1">D1</MenuItem>
                  <MenuItem value="D2">D2</MenuItem>
                  <MenuItem value="D3">D3</MenuItem>
                  <MenuItem value="D4">D4</MenuItem>
                  <MenuItem value="D5">D5</MenuItem>
                  <MenuItem value="D6">D6</MenuItem>
                  <MenuItem value="D7">D7</MenuItem>
                  <MenuItem value="D8">D8</MenuItem>
                  <MenuItem value="D9">D9</MenuItem>
                  <MenuItem value="D10">D10</MenuItem>
                  <MenuItem value="D11">D11</MenuItem>
                  <MenuItem value="D12">D12</MenuItem>

                  <MenuItem value="E1">E1</MenuItem>
                  <MenuItem value="E2">E2</MenuItem>
                  <MenuItem value="E3">E3</MenuItem>
                  <MenuItem value="E4">E4</MenuItem>
                  <MenuItem value="E5">E5</MenuItem>
                  <MenuItem value="E6">E6</MenuItem>
                  <MenuItem value="E7">E7</MenuItem>
                  <MenuItem value="E8">E8</MenuItem>
                  <MenuItem value="E9">E9</MenuItem>
                  <MenuItem value="E10">E10</MenuItem>
                  <MenuItem value="E11">E11</MenuItem>
                  <MenuItem value="E12">E12</MenuItem>

                  <MenuItem value="F1">F1</MenuItem>
                  <MenuItem value="F2">F2</MenuItem>
                  <MenuItem value="F3">F3</MenuItem>
                  <MenuItem value="F4">F4</MenuItem>
                  <MenuItem value="F5">F5</MenuItem>
                  <MenuItem value="F6">F6</MenuItem>
                  <MenuItem value="F7">F7</MenuItem>
                  <MenuItem value="F8">F8</MenuItem>
                  <MenuItem value="F9">F9</MenuItem>
                  <MenuItem value="F10">F10</MenuItem>
                  <MenuItem value="F11">F11</MenuItem>
                  <MenuItem value="F12">F12</MenuItem>

                  <MenuItem value="G1">G1</MenuItem>
                  <MenuItem value="G2">G2</MenuItem>
                  <MenuItem value="G3">G3</MenuItem>
                  <MenuItem value="G4">G4</MenuItem>
                  <MenuItem value="G5">G5</MenuItem>
                  <MenuItem value="G6">G6</MenuItem>
                  <MenuItem value="G7">G7</MenuItem>
                  <MenuItem value="G8">G8</MenuItem>
                  <MenuItem value="G9">G9</MenuItem>
                  <MenuItem value="G10">G10</MenuItem>
                  <MenuItem value="G11">G11</MenuItem>
                  <MenuItem value="G12">G12</MenuItem>

                  <MenuItem value="H1">H1</MenuItem>
                  <MenuItem value="H2">H2</MenuItem>
                  <MenuItem value="H3">H3</MenuItem>
                  <MenuItem value="H4">H4</MenuItem>
                  <MenuItem value="H5">H5</MenuItem>
                  <MenuItem value="H6">H6</MenuItem>
                  <MenuItem value="H7">H7</MenuItem>
                  <MenuItem value="H8">H8</MenuItem>
                  <MenuItem value="H9">H9</MenuItem>
                  <MenuItem value="H10">H10</MenuItem>
                  <MenuItem value="H11">H11</MenuItem>
                  <MenuItem value="H12">H12</MenuItem>
                </Select>
                <FormHelperText>Wajib Diisi</FormHelperText>
              </FormControl>
            </Grid>

            <Grid xs={12}>
              <SweetAlert
                show={show}
                title="Berhasil Daftar!"
                imageUrl={True}
                text="Data Berhasil DItambahkan"
                onConfirm={() => setShow(false)}
              />

              <SweetAlert
                show={falseShow}
                title="Gagal Daftar!"
                imageUrl={False}
                text="Barcode Sudah Terdaftar!"
                onConfirm={() => setFalse(false)}
              />
            </Grid>
            <div style={{ margin: 15 }}></div>
            <Button
              style={{ width: "100%" }}
              variant="contained"
              color="primary"
              type="submit"
            >
              Tambahkan Data
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
}

export default AddBarcode;
