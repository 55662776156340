import React, { useState, useEffect } from "react";
import { CSVReader } from "react-papaparse";
import { Grid } from "@material-ui/core";

const buttonRef = React.createRef();

function Importers(props) {
  var arr = [];
  var arry = [];
  const [data, setData] = useState([]);

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = (data) => {
    console.log("---------------------------");
    console.log("data:", data);
    setData(data);
    console.log("---------------------------");
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log("---------------------------");
    console.log(err);
    console.log("---------------------------");
  };

  const handleOnRemoveFile = (data) => {
    console.log("---------------------------");
    console.log(data);
    console.log("---------------------------");
  };

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  let datas = data ? data.length - 61 : 0;
  var i = datas;

  //   const nilai = () => {
  //     if (data != "") {
  //       for (i; i < data.length; i++) {
  //         //console.log(data[i].data[2]);

  //         if (data[i].data[4] != "") {
  //           if (data[i].data[4] == "Ct") {
  //             let j = i;
  //             i = i + j;

  //             for (j; j < data.length; j++) {
  //               if (data[j].data[4] == "Ct") {
  //                 let k = j;
  //                 console.log(data[j].data[4]);
  //               } else {
  //                 console.log(data[j].data[4]);
  //                 arry.push(data[j].data[4]);
  //               }
  //             }
  //           } else {
  //             arr.push(data[i].data[4]);
  //             console.log("dataz", data[i].data[4]);
  //           }
  //         }
  //       }
  //     }
  //     console.log(arr);
  //   };

  console.log("array:", arr);

  return (
    <>
      <h5>Basic Upload</h5>
      <CSVReader
        ref={buttonRef}
        onFileLoad={handleOnFileLoad}
        onError={handleOnError}
        noClick
        noDrag
        onRemoveFile={handleOnRemoveFile}
      >
        {({ file }) => (
          <aside
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 10,
            }}
          >
            <button
              type="button"
              onClick={handleOpenDialog}
              style={{
                borderRadius: 0,
                marginLeft: 0,
                marginRight: 0,
                width: "40%",
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              Browse file
            </button>
            <div
              style={{
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#ccc",
                height: 45,
                lineHeight: 2.5,
                marginTop: 5,
                marginBottom: 5,
                paddingLeft: 13,
                paddingTop: 3,
                width: "60%",
              }}
            >
              {file && file.name}
            </div>
            <button
              style={{
                borderRadius: 0,
                marginLeft: 0,
                marginRight: 0,
                paddingLeft: 20,
                paddingRight: 20,
              }}
              onClick={handleRemoveFile}
            >
              Remove
            </button>
          </aside>
        )}
      </CSVReader>

      {/* {nilai()}

      <Grid xs={12} container>
        <Grid xs={4}>
          {arr.map((item) => (
            <p>{item}</p>
          ))}
        </Grid>
        <Grid xs={4}>
          {arry.map((item) => (
            <p>{item}</p>
          ))}
        </Grid>
      </Grid> */}
    </>
  );
}

export default Importers;
