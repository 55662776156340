import React, { useState, useEffect } from "react";
import Pdf from "react-to-pdf";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useParams } from "react-router-dom";
import { Grid, Container, Button } from "@material-ui/core";
import "./style.css";
import { API } from "../config/api";

import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
const ref = React.createRef();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  title: {
    fontWeight: "bold",
  },
});

function GeneratePDF() {
  const [data, setData] = useState([]);
  const [form, setFormData] = useState({
    namaInstansi: "RSUD Unnamed",
  });
  const userId = localStorage.getItem("userId");
  const [namaFile, setNamaFile] = useState("");
  const [instansi, setInstansi] = useState([]);
  const { id } = useParams();

  const { namaInstansi } = form;

  useEffect(() => {
    const loadBarcode = async () => {
      const res = await API.get(`/barcode/30?nama=${id}`);
      console.log(res.data);
      setData(res.data);
    };
    loadBarcode();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const loadInstansi = async () => {
    const res = await API.get("/instansi");
    setInstansi(res.data.data);
  };

  useEffect(() => {
    loadInstansi();
  }, []);

  let date = new Date();
  let tahun = date.getFullYear();
  console.log(form.namaInstansi);
  const classes = useStyles();
  let arrayBulan = [
    "",
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];
  return (
    <div>
      <div className="buttonPdf">
        {data.map((item) => (
          <Pdf targetRef={ref} filename={`${item.nama}.pdf`}>
            {({ toPdf }) => (
              <Button variant="contained" color="primary" onClick={toPdf}>
                Generate Pdf
              </Button>
            )}
          </Pdf>
        ))}
      </div>
      <div style={{ margin: 20 }}></div>

      <div style={{ margin: 20 }}></div>
      <div className="App">
        <div ref={ref}>
          <div style={{ marginBottom: 50, color: "white" }}>Hello</div>

          <h1 className="text">
            {instansi.map((item) => (item.id == userId ? item.nama : ""))}
          </h1>
          <h2 className="text">Laporan Hasil Laboratorium</h2>
          <p className="titles">
            Kepala Departemen Laboratorium:{" "}
            {instansi.map((item) =>
              item.id == userId ? item.nama_dokter : ""
            )}
          </p>

          <hr className="borders" />

          <div>
            <Container maxWidth={700}>
              <Grid container xs={12}>
                <Grid xs={7}>
                  <Grid container xs={12}>
                    <Grid xs={3}>
                      <p className="tag-p-no-bold">No.Lab</p>
                    </Grid>
                    <Grid>
                      <p className="tag-p-no-bold">:</p>
                    </Grid>
                    <Grid xs={8}>
                      {data.map((item) => (
                        <p className="tag-p">&nbsp;&nbsp;&nbsp;{item.id}</p>
                      ))}
                    </Grid>
                  </Grid>

                  <Grid container xs={12}>
                    <Grid xs={3}>
                      <p className="tag-p-no-bold">Barcode</p>
                    </Grid>
                    <Grid>
                      <p className="tag-p-no-bold">:</p>
                    </Grid>
                    <Grid xs={8}>
                      <p className="tag-p">
                        &nbsp;&nbsp;&nbsp; {data.map((item) => item.nama)}
                      </p>
                    </Grid>
                  </Grid>

                  <Grid container xs={12}>
                    <Grid xs={3}>
                      <p className="tag-p-no-bold">Nama</p>
                    </Grid>
                    <Grid>
                      <p className="tag-p-no-bold">:</p>
                    </Grid>
                    <Grid xs={8}>
                      {data.map((item) => (
                        <p className="tag-p">
                          &nbsp;&nbsp;&nbsp;{item.profile.fullname}
                        </p>
                      ))}
                    </Grid>
                  </Grid>

                  <Grid container xs={12}>
                    <Grid xs={3}>
                      <p className="tag-p-no-bold">Tgl. Lahir</p>
                    </Grid>
                    <Grid>
                      <p className="tag-p-no-bold">:</p>
                    </Grid>
                    <Grid xs={8}>
                      {data.map((item) => (
                        <p className="tag-p">
                          &nbsp;&nbsp;&nbsp;
                          {item.profile.tanggal_lahir.substring(8, 10) +
                            " " +
                            arrayBulan[
                              item.profile.tanggal_lahir.substring(5, 7) < 10
                                ? item.profile.tanggal_lahir.substring(6, 7)
                                : item.profile.tanggal_lahir.substring(5, 7)
                            ] +
                            " " +
                            item.profile.tanggal_lahir.substring(0, 4)}
                        </p>
                      ))}
                    </Grid>
                  </Grid>

                  <Grid container xs={12}>
                    <Grid xs={3}>
                      <p className="tag-p-no-bold">Jenis Kelamin</p>
                    </Grid>
                    <Grid>
                      <p className="tag-p-no-bold">:</p>
                    </Grid>
                    <Grid xs={8}>
                      <p className="tag-p">
                        &nbsp;&nbsp;&nbsp;{" "}
                        {data.map((item) => item.profile.jenis_kelamin)}
                      </p>
                    </Grid>
                  </Grid>

                  <Grid container xs={12}>
                    <Grid xs={3}>
                      <p className="tag-p-no-bold">Alamat</p>
                    </Grid>
                    <Grid>
                      <p className="tag-p-no-bold">:</p>
                    </Grid>
                    <Grid xs={8}>
                      {data.map((item) => (
                        <p className="tag-p">
                          &nbsp;&nbsp;&nbsp;{item.profile.alamat}
                        </p>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid xs={5}>
                  <Grid container xs={12}>
                    <Grid xs={5}>
                      <p className="tag-p-no-bold">Dokter Pengirim</p>
                    </Grid>
                    <Grid>
                      <p className="tag-p-no-bold">:</p>
                    </Grid>
                    <Grid xs={6}>
                      <p className="tag-p">
                        &nbsp;&nbsp;&nbsp;
                        {instansi.map((item) =>
                          item.id == userId ? item.nama_dokter : ""
                        )}
                      </p>
                    </Grid>
                  </Grid>

                  <Grid container xs={12}>
                    <Grid xs={5}>
                      <p className="tag-p-no-bold">Tanggal Daftar</p>
                    </Grid>
                    <Grid>
                      <p className="tag-p-no-bold">:</p>
                    </Grid>
                    <Grid xs={6}>
                      {data.map((item) => (
                        <p className="tag-p">
                          &nbsp;&nbsp;&nbsp;{item.details.tanggal_run}
                        </p>
                      ))}
                    </Grid>
                  </Grid>

                  <Grid container xs={12}>
                    <Grid xs={5}>
                      <p className="tag-p-no-bold">Tanggal Run</p>
                    </Grid>
                    <Grid>
                      <p className="tag-p-no-bold">:</p>
                    </Grid>
                    <Grid xs={6}>
                      {data.map((item) => (
                        <p className="tag-p">
                          &nbsp;&nbsp;&nbsp;{item.details.tanggal_run}
                        </p>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </div>

          <div>
            <Container>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className={classes.title}>
                        Pemeriksaan
                      </TableCell>
                      <TableCell className={classes.title} align="center">
                        Hasil
                      </TableCell>
                      <TableCell className={classes.title} align="center">
                        Nilai Rujukan
                      </TableCell>
                      <TableCell className={classes.title} align="center">
                        Satuan
                      </TableCell>
                      <TableCell className={classes.title} align="center">
                        Metode
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">
                        <p className="tag-p">IMUNOLOGI</p>
                        <p className="tag-p-no-bold">
                          SARS-Cov-2-Antigen (Covid-19)
                        </p>
                      </TableCell>
                      <TableCell align="center">
                        {data.map((item) => (
                          <p className="tag-p-no-bold">{item.details.result}</p>
                        ))}
                      </TableCell>
                      <TableCell align="center">
                        {data.map((item) => (
                          <p className="tag-p-no-bold">{item.details.result}</p>
                        ))}
                      </TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">ICT</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneratePDF;
