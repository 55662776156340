import React, { useEffect, useState } from "react";
import {
  TextField,
  Grid,
  Button,
  Paper,
  Container,
  Typography,
} from "@material-ui/core";
import { API } from "../config/api";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withSwalInstance } from "sweetalert2-react";
import swal from "sweetalert2";
import True from "../icons/truere.png";
import { Link, useParams, useHistory } from "react-router-dom";
import { Style } from "@material-ui/icons";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function UpdateInstansi(props) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [form, setFormData] = useState({
    nama: "",
    alamat: "",
    telpon: "",
    fax: "",
    dokter: "",
  });

  const { id } = useParams();

  const SweetAlert = withSwalInstance(swal);
  const { nama, alamat, telpon, fax, dokter } = form;

  const handleChange = (e) => {
    setFormData({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    console.log(form);
  }, [form]);

  const loadData = async () => {
    const res = await API.get(`/instansi/${id}`);
    setData(res.data);
  };

  useEffect(() => {
    loadData();
  }, []);

  const history = useHistory();

  const handleStore = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      //   if (nama !== "") {
      //     form.append("nama", nama);
      //   } else {
      //     form.append("nama", nama);
      //   }

      //   if (alamat !== "") {
      //     form.append("alamat", alamat);
      //   } else {
      //     form.append("alamat", data[0].alamat);
      //   }

      //   if (telpon !== "") {
      //     form.append("telpon", telpon);
      //   } else {
      //     form.append("telpon", data[0].telpon);
      //   }

      //   if (fax !== "") {
      //     form.append("fax", fax);
      //   } else {
      //     form.append("fax", data[0].fax);
      //   }

      const body = JSON.stringify({
        nama: nama !== "" ? nama : data[0].nama,
        alamat: alamat !== "" ? alamat : data[0].alamat,
        telpon: telpon !== "" ? telpon : data[0].telpon,
        fax: fax !== "" ? fax : data[0].fax,
        nama_dokter: dokter !== "" ? dokter : data[0].nama_dokter,
        active: data[0].active,
      });

      await API.put(`/instansi/${id}`, body, config);

      setFormData({
        nama: "",
        alamat: "",
        telpon: "",
        fax: "",
        dokter: "",
      });

      setShow(true);
      history.push("/add-instansi");
    } catch (error) {}
  };

  const classes = useStyles();

  return (
    <div>
      <SweetAlert
        show={show}
        title="Berhasil Update Data!"
        imageUrl={True}
        text="Data Berhasil Diupdate"
        onConfirm={() => setShow(false)}
      />

      <div style={{ maxWidth: 840, margin: "0 auto" }}>
        <Paper>
          <Container style={{ paddingTop: 20, paddingBottom: 20 }}>
            <Typography>
              <h1 style={{ textAlign: "center" }}>Update Data Instansi</h1>
            </Typography>
            <form onSubmit={(e) => handleStore(e)}>
              <div style={{ margin: 10 }}></div>
              <Grid xs={12}>
                <TextField
                  style={{ width: "98%" }}
                  id="standard-textarea"
                  onChange={(e) => handleChange(e)}
                  name="nama"
                  value={nama}
                  label="Nama Instansi"
                  placeholder={data.length > 0 ? data[0].nama : ""}
                  multiline
                />
              </Grid>

              <div style={{ margin: 10 }}></div>

              <Grid xs={12}>
                <TextField
                  style={{ width: "98%" }}
                  id="standard-textarea"
                  onChange={(e) => handleChange(e)}
                  name="dokter"
                  value={dokter}
                  label="Nama Dokter"
                  placeholder={data.length > 0 ? data[0].nama_dokter : ""}
                  multiline
                />
              </Grid>
              <div style={{ margin: 10 }}></div>

              <Grid xs={12}>
                <TextField
                  style={{ width: "98%" }}
                  id="standard-textarea"
                  onChange={(e) => handleChange(e)}
                  name="alamat"
                  value={alamat}
                  label="Alamat Instansi"
                  placeholder={data.length > 0 ? data[0].alamat : ""}
                  multiline
                />
              </Grid>

              <div style={{ margin: 10 }}></div>

              <Grid xs={12}>
                <TextField
                  style={{ width: "98%" }}
                  id="standard-number"
                  label="Telpon"
                  onChange={(e) => handleChange(e)}
                  name="telpon"
                  value={telpon}
                  type="number"
                  placeholder={data.length > 0 ? data[0].telpon : ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <div style={{ margin: 10 }}></div>

              <Grid xs={12}>
                <TextField
                  style={{ width: "98%" }}
                  id="standard-number"
                  label="Fax"
                  placeholder={data.length > 0 ? data[0].fax : ""}
                  onChange={(e) => handleChange(e)}
                  name="fax"
                  value={fax}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <div style={{ margin: 10 }}></div>
              <div style={{ marginTop: 25 }}></div>
              <Button
                style={{ width: "100%" }}
                variant="contained"
                color="primary"
                type="submit"
              >
                Update
              </Button>
            </form>
          </Container>
        </Paper>
      </div>
    </div>
  );
}

export default UpdateInstansi;
