import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { API } from "../config/api";
import { useBarcode } from "react-barcodes";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function PrintBarcode(props) {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");
  setTimeout(function () {
    setLoading(false);
  }, 3000);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const res = await API.get(`/barcodes/${id}`);

        setData(res.data);

        setLoading(false);
      } catch (error) {}
    };

    loadData();
  }, []);

  const { inputRef } = useBarcode({
    value: data ? data[0].nama : "12121212121222222.212121.212121.A3",
    options: {
      background: "#FFFFFF",
    },
  });

  return (
    <div
      style={{
        margin: "0 auto",
        width: 720,
        display: "flex",
        alignItems: "center",
        height: 640,
      }}
    >
      <svg ref={inputRef} />
    </div>
  );
}

export default PrintBarcode;
