import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { API } from "../config/api";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function Operator() {
  const classes = useStyles();
  const [datas, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const res = await API.get("/operator");
        const hasil = res.data;
        console.log("hasil", hasil);
        setData(hasil);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    loadData();
  }, []);

  const number = 1;

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "480",
        }}
      >
        <SkeletonTheme color="#202020" highlightColor="#444">
          <p>
            <Skeleton count={3} />
          </p>
        </SkeletonTheme>
        ;
      </div>
    );
  }
  const userId = localStorage.getItem("userId");
  const role = localStorage.getItem("role");
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              No
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Nama
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Nama Instansi
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Username
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              No HP
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Role
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datas.map((item, index) => (
            <TableRow key={item.id}>
              <TableCell align="center" component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell align="center">{item.nama_operator}</TableCell>
              <TableCell align="center">{item.nama_instansi}</TableCell>
              <TableCell align="center">{item.username}</TableCell>
              <TableCell align="center">{item.nohp_operator}</TableCell>
              <TableCell align="center">{item.role}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
