import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { API, CityAPI } from "../config/api";
import { withSwalInstance } from "sweetalert2-react";
import swal from "sweetalert2";
import True from "../icons/truere.png";
import False from "../icons/falsere.png";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export default function InputData() {
  const SweetAlert = withSwalInstance(swal);
  const classes = useStyles();
  const [status, setStatus] = useState(false);
  const [show, setShow] = useState(false);
  const [provinsis, setProvinsi] = useState([]);
  const [kotas, setKota] = useState([]);
  const [kecamatan, setKecamatan] = useState([]);
  const [falseShow, setFalse] = useState(false);
  const [value, setValue] = React.useState("Controlled");
  const [form, setFormData] = useState({
    nik: "",
    fullname: "",
    date: "",
    gender: "",
    alamat: "",
    kota: "",
    kabupaten: "",
    provinsi: "",
    keterangan: "",
  });

  const {
    nik,
    fullname,
    date,
    gender,
    alamat,
    kota,
    provinsi,
    kabupaten,
    keterangan,
  } = form;

  useEffect(() => {
    console.log(form);
  }, [form]);

  const handleChange = (e) => {
    e.target.type === "date"
      ? setFormData({
          ...form,
          [e.target.name]: e.target.value,
        })
      : setFormData({
          ...form,
          [e.target.name]: e.target.value,
        });
  };

  useEffect(() => {
    const loadKota = async () => {
      const res = await CityAPI.get(
        `/daerahindonesia/kota?id_provinsi=${provinsi}`
      );
      setKota(res.data.kota_kabupaten);
    };
    console.log(kota);
    loadKota();
  }, [provinsi]);

  useEffect(() => {
    const loadCity = async () => {
      const res = await CityAPI.get("/daerahindonesia/provinsi");
      setProvinsi(res.data.provinsi);
    };
    console.log(provinsi);
    loadCity();
  }, []);

  useEffect(() => {
    const loadKecamatan = async () => {
      const res = await CityAPI.get(
        `daerahindonesia/kecamatan?id_kota=${kota}`
      );
      console.log(res.data);
      setKecamatan(res.data.kecamatan);
    };
    console.log(kabupaten);
    loadKecamatan();
  }, [kota]);

  const userId = localStorage.getItem("userId");

  const handleStore = async (e) => {
    e.preventDefault(e);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      if (nik.length !== 16) {
        alert(`NIK Harus Berjumlah 16 Karakter, Jumlah Karakter ${nik.length}`);
      } else {
        const form = new FormData();

        form.append("nik", nik);
        form.append("instansi_id", userId);
        form.append("fullname", fullname);
        form.append("tanggal_lahir", date);
        form.append("jenis_kelamin", gender);
        form.append("alamat", alamat);
        kotas.map((item) =>
          item.id == kota ? form.append("kota", item.nama) : ""
        );
        provinsis.map((item) =>
          item.id == provinsi ? form.append("provinsi", item.nama) : ""
        );
        kecamatan.map((item) =>
          item.id == kabupaten ? form.append("kabupaten", item.nama) : ""
        );
        form.append("keterangan", keterangan);

        const res = await API.post(`/profile/${userId}/${nik}`, form, config);

        console.log(res);
        setStatus(res.data.status);

        setShow(true);

        if (status == true) {
          setFormData({
            nik: "",
            fullname: "",
            date: "",
            gender: "",
            alamat: "",
            kota: "",
            kabupaten: "",
            provinsi: "",
            keterangan: "",
          });
        }
      }
    } catch (err) {
      setFalse(true);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Paper elevation={5} square style={{ width: 860 }}>
        <div
          style={{
            padding: 20,
          }}
        >
          <form className={classes.root} onSubmit={(e) => handleStore(e)}>
            <div>
              <Grid xs={12}>
                <TextField
                  style={{ width: "98%" }}
                  id="standard-number"
                  required={true}
                  label="NIK"
                  onChange={(e) => handleChange(e)}
                  name="nik"
                  value={nik}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid xs={12}>
                <TextField
                  required={true}
                  style={{ width: "98%" }}
                  id="standard-textarea"
                  onChange={(e) => handleChange(e)}
                  name="fullname"
                  value={fullname}
                  label="Nama Lengkap"
                  placeholder="Nama Lengkap"
                  multiline
                />
              </Grid>

              <div style={{ marginTop: 20 }}></div>

              <Grid xs={12}>
                <TextField
                  required={true}
                  style={{ width: "98%" }}
                  id="date"
                  label="Tanggal Lahir"
                  name="date"
                  onChange={(e) => handleChange(e)}
                  value={date}
                  type="date"
                  defaultValue="2017-05-24"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <div style={{ marginTop: 20 }}></div>

              <Grid xs={12}>
                <FormControl style={{ marginLeft: 10, width: "98%" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Jenis Kelamin
                  </InputLabel>
                  <Select
                    required={true}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    onChange={(e) => handleChange(e)}
                    name="gender"
                    value={gender}
                  >
                    <MenuItem value="Pria">Pria</MenuItem>
                    <MenuItem value="Wanita">Wanita</MenuItem>
                  </Select>
                  <FormHelperText>Some important helper text</FormHelperText>
                </FormControl>
              </Grid>

              <Grid xs={12}>
                <TextField
                  required={true}
                  style={{ width: "98%" }}
                  id="standard-textarea"
                  label="Alamat"
                  onChange={(e) => handleChange(e)}
                  name="alamat"
                  value={alamat}
                  placeholder="Alamat"
                  multiline
                />
              </Grid>

              <div style={{ marginTop: 20 }}></div>

              <Grid xs={12}>
                <FormControl style={{ marginLeft: 10, width: "98%" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Provinsi
                  </InputLabel>
                  <Select
                    required={true}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    onChange={(e) => handleChange(e)}
                    name="provinsi"
                    value={provinsi}
                  >
                    {provinsis.map((item) => (
                      <MenuItem value={item.id}>{item.nama}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <div style={{ marginTop: 20 }}></div>

              <Grid xs={12}>
                <FormControl style={{ marginLeft: 10, width: "98%" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Kota
                  </InputLabel>
                  <Select
                    required={true}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    onChange={(e) => handleChange(e)}
                    name="kota"
                    value={kota}
                    onChange={handleChange}
                  >
                    {kotas.length > 0 ? (
                      kotas.map((item) => (
                        <MenuItem value={item.id}>{item.nama}</MenuItem>
                      ))
                    ) : (
                      <MenuItem value="nama" disabled>
                        Pilih Provinsi Terlebih Dahulu
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <div style={{ marginTop: 20 }}></div>

              <Grid xs={12}>
                <FormControl style={{ marginLeft: 10, width: "98%" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Kecamatan
                  </InputLabel>
                  <Select
                    required={true}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    onChange={(e) => handleChange(e)}
                    name="kabupaten"
                    value={kabupaten}
                    onChange={handleChange}
                  >
                    {kecamatan.length > 0 ? (
                      kecamatan.map((item) => (
                        <MenuItem value={item.id}>{item.nama}</MenuItem>
                      ))
                    ) : (
                      <MenuItem value="nama" disabled>
                        Pilih Kota Terlebih Dahulu
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid xs={12}>
                <TextField
                  style={{ width: "98%" }}
                  id="standard-textarea"
                  label="Keterangan"
                  onChange={(e) => handleChange(e)}
                  name="keterangan"
                  value={keterangan}
                  placeholder="Keterangan"
                  multiline
                />
              </Grid>

              <Grid xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{
                    marginLeft: 8,
                    width: "98%",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  Daftarkan Pasien
                </Button>
              </Grid>

              <Grid xs={12}>
                {status == false ? (
                  <SweetAlert
                    show={show}
                    title="Berhasil Daftar!"
                    imageUrl={True}
                    text="Data Berhasil DItambahkan"
                    onConfirm={() => setShow(false)}
                  />
                ) : (
                  <SweetAlert
                    show={show}
                    title="Gagal Daftar!"
                    imageUrl={False}
                    text="Nik Sudah Terdaftar"
                    onConfirm={() => setShow(false)}
                  />
                )}

                <SweetAlert
                  show={falseShow}
                  title="Gagal Daftar!"
                  imageUrl={False}
                  text="Nomor KTP Sudah Terdaftar!"
                  onConfirm={() => setFalse(false)}
                />
              </Grid>
            </div>
          </form>
        </div>
      </Paper>
    </div>
  );
}
