import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { API } from "../config/api";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function Hasil() {
  const classes = useStyles();
  const [datas, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const res = await API.get("/profile");
        const hasil = res.data;
        console.log(hasil);
        setData(hasil);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    loadData();
  }, []);

  const number = 1;

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "480",
        }}
      >
        <SkeletonTheme color="#202020" highlightColor="#444">
          <p>
            <Skeleton count={3} />
          </p>
        </SkeletonTheme>
        ;
      </div>
    );
  }
  const userId = localStorage.getItem("userId");
  const role = localStorage.getItem("role");
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              NIK
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Nama
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Jenis Kelamin
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Alamat
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Kota
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Provinsi
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Keterangan
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Jumlah SWAB
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datas &&
            datas.map((item) =>
              role == "User" && item.instansi_id == userId ? (
                <TableRow key={item.id}>
                  <TableCell component="th" scope="row">
                    {item.nik}
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      to={`/profile/${item.nik}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Button style={{ fontWeight: "bold" }}>
                        {item.fullname}
                      </Button>
                    </Link>
                  </TableCell>
                  <TableCell align="center">{item.jenis_kelamin}</TableCell>
                  <TableCell align="left">{item.alamat}</TableCell>
                  <TableCell align="center">{item.kota}</TableCell>
                  <TableCell align="center">{item.provinsi}</TableCell>
                  <TableCell align="center">{item.keterangan}</TableCell>
                  <TableCell align="center">{item.barcode.length}</TableCell>
                  <TableCell align="center">
                    <Link
                      to={`add-barcode/${item.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "#4caf50", color: "white" }}
                      >
                        Generate Barcode
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ) : role == "Admin" ? (
                <TableRow key={item.id}>
                  <TableCell component="th" scope="row">
                    {item.nik}
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      to={`/profile/${item.nik}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Button style={{ fontWeight: "bold" }}>
                        {item.fullname}
                      </Button>
                    </Link>
                  </TableCell>
                  <TableCell align="center">{item.jenis_kelamin}</TableCell>
                  <TableCell align="left">{item.alamat}</TableCell>
                  <TableCell align="center">{item.kota}</TableCell>
                  <TableCell align="center">{item.provinsi}</TableCell>
                  <TableCell align="center">{item.keterangan}</TableCell>
                  <TableCell align="center">{item.barcode.length}</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              ) : (
                ""
              )
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
