import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { API } from "../config/api";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper, Button, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function ProfileDetail(props) {
  const [datas, setData] = useState([]);
  const [barcodeData, setBarcode] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  const [isEdit, setEdit] = useState(false);
  const [form, setForm] = useState({
    fullname: "",
    nik: "",
    alamat: "",
  });

  const { fullname, nik, alamat } = form;

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    console.log(form);
  }, [form]);

  const loadData = async () => {
    try {
      setLoading(true);
      const res = await API.get(`/profile/${id}`);
      const hasil = res.data;
      console.log(hasil);
      setData(hasil);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const classes = useStyles();

  useEffect(() => {
    const loadBarcode = async () => {
      try {
        setLoading(true);
        const res = await API.get(`/barcode/detail/${id}`);
        const hasils = res.data;
        console.log(hasils);
        setBarcode(hasils);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
        console.log("KAKAKA");
      }
    };

    loadBarcode();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        fullname: fullname !== "" ? fullname : datas[0].fullname,
        nik: nik !== "" ? nik : datas[0].nik,
        alamat: alamat !== "" ? alamat : datas[0].alamat,
      });

      await API.put(`/profile/${id}`, body, config);

      setEdit(false);

      setForm({
        alamat: "",
        nik: "",
        fullname: "",
      });

      loadData();
    } catch (error) {}
  };

  const role = localStorage.getItem("role");
  return (
    <div>
      {datas.map((item, index) => (
        <Grid xs={12}>
          <Paper style={{ padding: 20 }} elevation={1} square>
            <Grid xs={12} container>
              <Grid xs={10}>
                <Grid xs={12} style={{ margin: 20 }} container>
                  <Grid xs={3}>
                    <Typography>Nama</Typography>
                  </Grid>
                  <Grid xs={8}>
                    {isEdit ? (
                      <TextField
                        id="standard-basic"
                        onChange={(e) => handleChange(e)}
                        name="fullname"
                        value={fullname}
                        placeholder={item.fullname}
                      />
                    ) : (
                      <Typography style={{ fontWeight: "bold" }}>
                        {item.fullname}
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid xs={12} style={{ margin: 20 }} container>
                  <Grid xs={3}>
                    <Typography>Tanggal Lahir</Typography>
                  </Grid>
                  <Grid xs={8}>
                    <Typography style={{ fontWeight: "bold" }}>
                      {item.tanggal_lahir}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid xs={12} style={{ margin: 20 }} container>
                  <Grid xs={3}>
                    <Typography>Jenis Kelamin</Typography>
                  </Grid>
                  <Grid xs={8}>
                    <Typography style={{ fontWeight: "bold" }}>
                      {item.jenis_kelamin}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid xs={12} style={{ margin: 20 }} container>
                  <Grid xs={3}>
                    <Typography>NIK</Typography>
                  </Grid>
                  <Grid xs={8}>
                    {isEdit ? (
                      <TextField
                        id="standard-number"
                        onChange={(e) => handleChange(e)}
                        name="nik"
                        value={nik}
                        placeholder={item.nik}
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    ) : (
                      <Typography style={{ fontWeight: "bold" }}>
                        {item.nik}
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid xs={12} style={{ margin: 20 }} container>
                  <Grid xs={3}>
                    <Typography>Alamat</Typography>
                  </Grid>
                  <Grid xs={8}>
                    {isEdit ? (
                      <TextField
                        required={true}
                        style={{ width: "98%" }}
                        id="standard-textarea"
                        label="Alamat"
                        onChange={(e) => handleChange(e)}
                        name="alamat"
                        value={alamat}
                        placeholder="Alamat"
                        multiline
                      />
                    ) : (
                      <Typography style={{ fontWeight: "bold" }}>
                        {item.alamat}
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid xs={12} style={{ margin: 20 }} container>
                  <Grid xs={3}>
                    <Typography>Provinsi</Typography>
                  </Grid>
                  <Grid xs={8}>
                    <Typography style={{ fontWeight: "bold" }}>
                      {item.provinsi}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid xs={12} style={{ margin: 20 }} container>
                  <Grid xs={3}>
                    <Typography>Kabupaten</Typography>
                  </Grid>
                  <Grid xs={8}>
                    <Typography style={{ fontWeight: "bold" }}>
                      {item.kabupaten}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid xs={12} style={{ margin: 20 }} container>
                  <Grid xs={3}>
                    <Typography>Kota</Typography>
                  </Grid>
                  <Grid xs={8}>
                    <Typography style={{ fontWeight: "bold" }}>
                      {item.kota}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={2}>
                {isEdit ? (
                  <Button
                    color="primary"
                    onClick={(e) => handleUpdate(e)}
                    variant="contained"
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    onClick={() => setEdit(true)}
                    variant="contained"
                  >
                    Edit
                  </Button>
                )}
              </Grid>
            </Grid>
          </Paper>
          <div style={{ margin: 50 }}></div>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">No</TableCell>
                  <TableCell align="center">Nama</TableCell>
                  <TableCell align="center">NIK</TableCell>
                  <TableCell align="center">Posisi Tabung</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {barcodeData.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{row.nama}</TableCell>
                    <TableCell align="center">{row.nik}</TableCell>
                    <TableCell align="center">{row.posisi_tabung}</TableCell>
                    <TableCell align="center">
                      <Grid container spacing={1}>
                        {row.details !== null && role == "User" ? (
                          <>
                            <Grid sm={6}>
                              <Link
                                style={{ textDecoration: "none" }}
                                to={`/generate-pdf/${row.nama}`}
                              >
                                <Button variant="contained" color="secondary">
                                  Print PDF
                                </Button>
                              </Link>
                            </Grid>

                            <Grid sm={6}>
                              <Link
                                style={{ textDecoration: "none" }}
                                to={`/barcode-print/${item.id}`}
                              >
                                <Button variant="contained" color="primary">
                                  Print Barcode
                                </Button>
                              </Link>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid sm={6}>
                              <Link style={{ textDecoration: "none" }}>
                                <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: "grey",
                                    color: "white",
                                  }}
                                >
                                  Print PDF
                                </Button>
                              </Link>
                            </Grid>

                            <Grid sm={6}>
                              <Link
                                style={{ textDecoration: "none" }}
                                to={`/barcode-print/${item.id}`}
                              >
                                <Button variant="contained" color="primary">
                                  Print Barcode
                                </Button>
                              </Link>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ))}
    </div>
  );
}

export default ProfileDetail;
