import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import React, { useState, useEffect } from "react";
import { Paper, TextField, Button } from "@material-ui/core";
import { API } from "../config/api";
import { useHistory } from "react-router-dom";
import { ReplayOutlined } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import FilledInput from "@material-ui/core/FilledInput";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import clsx from "clsx";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    marginTop: 100,
    margin: "0 auto",
  },
}));

export default function Login() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [user, setUser] = useState("");
  const [token, setToken] = useState("");
  const [form, setForm] = useState({
    username: "",
    password: "",
    namaInstansi: "",
    fullName: "",
    nohp: "",
    idInstansi: "",
  });
  const [instansi, setInstansi] = useState([]);

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const history = useHistory();
  const { username, password, namaInstansi, fullName, nohp, idInstansi } = form;

  const handleChanges = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleStore = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const form = new FormData();
      form.append("username", username);
      form.append("password", password);

      const res = await API.post("/login", form, config);

      console.log(res.data.data.api_token);
      localStorage.setItem("user", res.data.data.api_token);
      localStorage.setItem("userId", res.data.data.operator.id_instansi);
      localStorage.setItem("role", res.data.data.operator.role);

      setToken(localStorage.getItem("user"));
      window.location.reload();
    } catch (err) {
      console.log(err.message);
      alert("Gagal Login, Password atau Username Tidak Terdaftar");
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const form = new FormData();
      form.append("username", username);
      form.append("password", password);
      form.append("id_instansi", idInstansi);
      instansi.map((item) =>
        item.id == idInstansi ? form.append("nama_instansi", item.nama) : ""
      );
      form.append("nohp_operator", nohp);
      form.append("nama_operator", fullName);
      form.append("role", "User");

      const res = await API.post("/register", form, config);

      setForm({
        username: "",
        idInstansi: "",
        nohp: "",
        fullName: "",
        password: "",
      });

      alert("Berhasil Mendaftar!!");
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const loadInstansi = async () => {
    const res = await API.get("/instansi");
    setInstansi(res.data.data);
    console.log(instansi);
  };

  useEffect(() => {
    loadInstansi();
  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Login User" {...a11yProps(0)} />
          <Tab label="Register User" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel
          style={{ width: "100%" }}
          value={value}
          index={0}
          dir={theme.direction}
        >
          <div>
            <Paper
              elevation={2}
              height={100}
              style={{ width: "90%", padding: 20 }}
              square
            >
              <h1 style={{ textAlign: "center" }}>Login User</h1>

              <form onSubmit={(e) => handleStore(e)}>
                <TextField
                  id="standard-basic"
                  label="Username Account"
                  name="username"
                  value={username}
                  onChange={(e) => handleChanges(e)}
                  style={{ width: "100%" }}
                  multiline
                  placeholder="Masukkan Username"
                />

                <div style={{ margin: 10 }}></div>

                <FormControl>
                  <InputLabel htmlFor="standard-adornment-password">
                    Password
                  </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    name="password"
                    value={password}
                    style={{ width: 405, marginTop: 20, marginBottom: 20 }}
                    onChange={(e) => handleChanges(e)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <div style={{ margin: 10 }}></div>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ width: "100%" }}
                >
                  Login
                </Button>
                <div style={{ margin: 10 }}></div>
              </form>
            </Paper>
          </div>
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>
          <div>
            <Paper
              elevation={2}
              height={100}
              style={{ width: "90%", padding: 20 }}
              square
            >
              <h1 style={{ textAlign: "center" }}>Register User</h1>

              <form onSubmit={(e) => handleRegister(e)}>
                <TextField
                  id="standard-basic"
                  label="Nama Operator"
                  name="fullName"
                  value={fullName}
                  onChange={(e) => handleChanges(e)}
                  style={{ width: "100%" }}
                  multiline
                  placeholder="Mis. Sandi"
                />

                <div style={{ margin: 10 }}></div>

                <TextField
                  id="standard-basic"
                  label="Username"
                  style={{ width: "100%" }}
                  name="username"
                  value={username}
                  onChange={(e) => handleChanges(e)}
                  multiline
                  placeholder="Mis. faizal_16"
                />

                <div style={{ margin: 10 }}></div>

                <FormControl>
                  <InputLabel htmlFor="standard-adornment-password">
                    Password
                  </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    name="password"
                    value={password}
                    style={{ width: 405 }}
                    onChange={(e) => handleChanges(e)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                <div style={{ margin: 10 }}></div>

                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-mutiple-name-label">
                    Nama Instansi
                  </InputLabel>
                  <Select
                    style={{ width: 405 }}
                    labelId="demo-mutiple-name-label"
                    onChange={(e) => handleChanges(e)}
                    id="demo-mutiple-name"
                    value={idInstansi}
                    name="idInstansi"
                  >
                    {instansi.map((item) =>
                      item.active == "Aktif" ? (
                        <MenuItem value={item.id}>{item.nama}</MenuItem>
                      ) : (
                        ""
                      )
                    )}
                  </Select>
                </FormControl>

                <div style={{ margin: 10 }}></div>

                <TextField
                  style={{ width: "98%" }}
                  id="standard-number"
                  required={true}
                  label="Nomor HP"
                  onChange={(e) => handleChanges(e)}
                  name="nohp"
                  value={nohp}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <div style={{ margin: 10 }}></div>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ width: "100%" }}
                >
                  Register
                </Button>
                <div style={{ margin: 10 }}></div>
              </form>
            </Paper>
          </div>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
