import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { useParams, Link } from "react-router-dom";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { API } from "../config/api";
import {
  Button,
  Grid,
  TextField,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";
import Axios from "axios";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { withSwalInstance } from "sweetalert2-react";
import swal from "sweetalert2";
import True from "../icons/truere.png";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3f51b5",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    width: 640,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function DetailHasil() {
  const role = localStorage.getItem("role");
  let date = new Date();
  let detik = date.getSeconds();
  let detiks = detik > 9 ? detik : "0" + detik;
  let menit = date.getMinutes();
  let menits = menit > 9 ? menit : "0" + menit;
  let jam = date.getHours();
  let jams = jam > 9 ? jam : "0" + jam;

  let tanggal = 12;
  let tanggals = tanggal.length > 9 ? tanggal : "0" + tanggal;
  let bulan = date.getMonth() + 1;
  let bulans = bulan > 9 ? bulan : "0" + bulan;
  let tahun = date.getFullYear();
  let now =
    tahun +
    "-" +
    bulans +
    "-" +
    tanggals +
    " " +
    jams +
    ":" +
    menits +
    ":" +
    detiks;

  let nows = tahun + "-" + bulans + "-" + tanggals;

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const classes = useStyles();
  const [keterangan, setKeterangan] = useState("");
  const SweetAlert = withSwalInstance(swal);
  const [show, setShow] = useState(false);
  const [hasil, setHasil] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [opens, setOpens] = useState(false);
  const [instansi, setInstansi] = useState([]);
  const [dataBarcode, setBarcodes] = useState([]);
  const [dataHasil, setDataHasil] = useState([]);
  const [form, setFormData] = useState({
    start: "",
    to: "",
    filter: "",
    nama: "",
    operator: "",
    mesin: "",
    instansisz_id: "",
  });

  const { start, to, filter, nama, mesin, operator, instansisz_id } = form;

  const previousPage = () => {
    setPage(page <= 1 ? page : page - 1);
  };

  const nextPage = () => {
    setPage(page === total ? total : page + 1);
  };

  useEffect(() => {
    const HasilData = async () => {
      setData([]);

      const res = await API.get(
        `/hasil-details?instansi=${instansisz_id}&page=${page}&from=${start}&to=${to}&filter=${filter}&search=${nama}&operator=${operator}&mesin=${mesin}`
      );

      setTotal(Math.ceil(res.data.total / res.data.per_page));

      setData(res.data.data);
    };
    HasilData();
  }, [page, start, to, filter, nama, operator, mesin, instansisz_id]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpens = () => {
    setOpens(true);
  };

  const loadBarcode = async () => {
    const res = await API.get("/barcode");
    console.log(res);
    setBarcodes(res.data.data);
  };

  useEffect(() => {
    loadBarcode();
  }, []);

  const handleChangez = (e) => {
    e.target.type === "date"
      ? setFormData({
          ...form,
          [e.target.name]: e.target.value,
        })
      : setFormData({
          ...form,
          [e.target.name]: e.target.value,
        });
  };

  useEffect(() => {
    console.log(form);
  }, [form]);

  useEffect(() => {
    const loadDetail = async () => {
      const res = await API.get(`/detail-hasil/${id}`);
      setKeterangan(res.data.data.map((item) => item.keterangan));
      setLoading(false);
    };
    loadDetail();
  }, [id]);

  const handleClose = () => {
    setOpen(false);
    setKeterangan("");
  };

  const handleCloses = () => {
    setOpens(false);
  };

  const handleChange = (e) => {
    setKeterangan(e.target.value);
  };

  useEffect(() => {
    const loadFilter = async () => {
      const res = await API.get("/hasil");
      setHasil(res.data.data.data);
    };

    const loadInstansi = async () => {
      const res = await API.get("/instansi");
      setInstansi(res.data.data);
    };

    loadInstansi();
    loadFilter();
  }, []);

  console.log("INSTANSI", instansi);

  const handleStore = async (e) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({ keterangan_details: keterangan });
      const res = await API.put(`/detail-hasil/${id}`, body, config);

      setShow(true);
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const userId = localStorage.getItem("userId");

  return (
    <TableContainer component={Paper}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 style={{ textAlign: "center" }} id="transition-modal-title">
              Ubah Keterangan
            </h2>
            <form>
              {data
                ? data.map((item) =>
                    item.id_details == id ? (
                      <div>
                        <TextField
                          required={true}
                          style={{ width: "98%" }}
                          id="standard-textarea"
                          name="keterangan"
                          style={{ width: "100%" }}
                          value={keterangan}
                          onChange={(e) => handleChange(e)}
                          label="Keterangan"
                          placeholder="Keterangan"
                          multiline
                        />
                      </div>
                    ) : (
                      ""
                    )
                  )
                : ""}
            </form>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: 10, width: "100%" }}
              onClick={(e) => handleStore(e)}
            >
              Ubah Data
            </Button>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={opens}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={opens}>
          <div className={classes.paper}>
            <h2 style={{ textAlign: "center" }} id="transition-modal-title">
              NC PC Tidak Bisa Di Print
            </h2>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: 10, width: "100%" }}
              onClick={handleCloses}
            >
              Tutup
            </Button>
          </div>
        </Fade>
      </Modal>

      <div style={{ margin: 10 }}>
        <Grid container xs={12} sm={12}>
          <Grid container xs={12}>
            <Button
              style={{ margin: 10 }}
              variant="contained"
              color="secondary"
              onClick={() => previousPage()}
            >
              Previous
            </Button>
            <h2>
              {page} / {total ? total : 1}
            </h2>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: 10 }}
              onClick={() => nextPage()}
            >
              Next
            </Button>
          </Grid>

          <Grid style={{ marginLeft: 15, marginTop: 5 }} xs={12} sm={2}>
            <TextField
              style={{ width: "98%" }}
              id="date"
              label="Tanggal Mulai Run"
              name="start"
              onChange={(e) => handleChangez(e)}
              value={start}
              type="date"
              defaultValue="2017-05-24"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid style={{ marginLeft: 15, marginTop: 5 }} xs={12} sm={2}>
            <TextField
              style={{ width: "98%" }}
              id="date"
              label="Tanggal Selesai Run"
              name="to"
              onChange={(e) => handleChangez(e)}
              value={to}
              type="date"
              defaultValue="2017-05-24"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid sm={2}>
            <FormControl style={{ marginLeft: 10, marginTop: 5, width: "98%" }}>
              <TextField
                id="standard-basic"
                label="Cari Nama File"
                name="filter"
                onChange={(e) => handleChangez(e)}
                value={filter}
              />
            </FormControl>
          </Grid>

          <Grid sm={2}>
            <FormControl style={{ marginLeft: 10, marginTop: 5, width: "98%" }}>
              <TextField
                id="standard-basic"
                label="Cari Nama Pasien"
                name="nama"
                onChange={(e) => handleChangez(e)}
                value={nama}
              />
            </FormControl>
          </Grid>

          <Grid sm={2}>
            <FormControl style={{ marginLeft: 10, marginTop: 5, width: "98%" }}>
              <TextField
                id="standard-basic"
                label="Cari Nama Operator"
                name="operator"
                onChange={(e) => handleChangez(e)}
                value={operator}
              />
            </FormControl>
          </Grid>

          <Grid sm={2}>
            <FormControl style={{ marginLeft: 15, marginTop: 5, width: "98%" }}>
              <TextField
                id="standard-basic"
                label="Cari Mesin ID"
                name="mesin"
                onChange={(e) => handleChangez(e)}
                value={mesin}
              />
            </FormControl>
          </Grid>

          {role == "Admin" ? (
            <Grid sm={2}>
              <FormControl
                style={{ marginLeft: 30, marginTop: 5, width: "98%" }}
              >
                <InputLabel id="demo-simple-select-helper-label">
                  Nama Instansi
                </InputLabel>
                <Select
                  required={true}
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={instansisz_id}
                  onChange={(e) => handleChangez(e)}
                  name="instansisz_id"
                >
                  {instansi.map((item) => (
                    <MenuItem value={item.id}>{item.nama}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>

      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">No</StyledTableCell>
            <StyledTableCell align="center">Nama File</StyledTableCell>
            <StyledTableCell align="center">Tanggan Run</StyledTableCell>
            <StyledTableCell align="center">Mesin ID</StyledTableCell>
            <StyledTableCell align="center">Operator</StyledTableCell>
            <StyledTableCell align="center">Nama</StyledTableCell>
            <StyledTableCell align="center">Internal Control</StyledTableCell>
            <StyledTableCell align="center">CT Gen E</StyledTableCell>
            <StyledTableCell align="center">CT Gen ORF</StyledTableCell>
            <StyledTableCell align="center">Hasil</StyledTableCell>
            <StyledTableCell align="center">Keterangan</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data
            ? data.map((row, index) =>
                role == "Admin" ? (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell align="center" component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.nama_file_id}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.tanggal_run}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.mesin_id}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.operator}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ fontWeight: "bold" }}
                      align="center"
                    >
                      {row.fullname !== null ? row.fullname : row.nama_id}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.internal_control}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.ct_gen_e <= 1 ? "" : row.ct_gen_e}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.ct_orf <= 1 ? "" : row.ct_orf}
                    </StyledTableCell>
                    {row.result == "Positif" ? (
                      <StyledTableCell align="center">
                        <Button
                          style={{
                            color: "green",
                            fontWeight: "bold",
                          }}
                        >
                          {row.result}
                        </Button>
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell align="center">
                        <Button
                          style={{
                            color: "red",
                            fontWeight: "bold",
                          }}
                        >
                          {row.result}
                        </Button>
                      </StyledTableCell>
                    )}

                    <StyledTableCell align="center">
                      {row.keterangan_details}
                    </StyledTableCell>
                    <StyledTableCell align="center"></StyledTableCell>
                  </StyledTableRow>
                ) : role == "User" && row.instansis_id == userId ? (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell align="center" component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center" component="th" scope="row">
                      {row.nama_file_id}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.tanggal_run}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.mesin_id}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.operator}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ fontWeight: "bold" }}
                      align="center"
                    >
                      {row.fullname !== null ? row.fullname : row.nama_id}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.internal_control}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.ct_gen_e <= 1 ? "" : row.ct_gen_e}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.ct_orf <= 1 ? "" : row.ct_orf}
                    </StyledTableCell>
                    {row.result == "Positif" ? (
                      <StyledTableCell align="center">
                        <Button
                          style={{
                            color: "green",
                            fontWeight: "bold",
                          }}
                        >
                          {row.result}
                        </Button>
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell align="center">
                        <Button
                          style={{
                            color: "red",
                            fontWeight: "bold",
                          }}
                        >
                          {row.result}
                        </Button>
                      </StyledTableCell>
                    )}

                    <StyledTableCell align="center">
                      {row.keterangan_details}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={`/hasil-detail/${row.id_details}`}
                      >
                        <Button
                          variant="contained"
                          style={{ margin: 5 }}
                          color="secondary"
                          id="transition-modal-description"
                          onClick={handleOpen}
                        >
                          Edit
                        </Button>
                      </Link>

                      {row.fullname != "" ? (
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/generate-pdf/${row.nama}`}
                        >
                          <Button
                            variant="contained"
                            style={{ margin: 5 }}
                            color="primary"
                            id="transition-modal-description"
                            onClick={handleOpen}
                          >
                            Print
                          </Button>
                        </Link>
                      ) : (
                        <Button
                          variant="contained"
                          style={{ margin: 5 }}
                          color="primary"
                          id="transition-modal-description"
                          onClick={handleOpens}
                        >
                          Print
                        </Button>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  ""
                )
              )
            : ""}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
