import React, { useState, useEffect } from "react";
import Homes from "../icons/home.png";
import CountUp from "react-countup";
import { Grid, Paper } from "@material-ui/core";
import { API } from "../config/api";
function Home(props) {
  const [dataUser, setUser] = useState([]);
  const [dataBarcode, setBarcode] = useState([]);
  const [dataHasil, setHasil] = useState([]);
  const [dataDetail, setDetail] = useState([]);
  const [dataInstansi, setInstansi] = useState([]);

  const role = localStorage.getItem("role");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const loadUser = async () => {
      const res = await API.get("/profile");
      setUser(res.data);
    };
    loadUser();
    const loadBarcode = async () => {
      const res = await API.get(`/barcode/instansi/${userId}`);
      setBarcode(res.data.data);
    };
    loadBarcode();

    const loadHasil = async () => {
      const res = await API.get("/hasil");
      setHasil(res.data.data.data);
    };
    loadHasil();

    const loadDetails = async () => {
      const res = await API.get("/hasil-detail");
      setDetail(res.data);
      console.log("DETAILS", res.data);
    };
    loadDetails();

    const loadInstansi = async () => {
      const res = await API.get("/instansi");
      setInstansi(res.data.data);
    };
    loadInstansi();
  }, []);

  let userArr = [];
  let arrBarcode = [];
  let arrHasil = [];
  let arrDetail = [];

  dataUser.map((item) =>
    item.instansi_id == userId ? userArr.push("Match") : ""
  );

  dataBarcode.map((item) =>
    item.instansi_id == userId ? arrBarcode.push("Match") : ""
  );

  dataHasil.map((item) =>
    item.instansi_id == userId ? arrHasil.push("Match") : ""
  );

  dataDetail.map((item) =>
    item.instansis_id == userId ? arrDetail.push("Match") : ""
  );

  console.log("Tips", arrDetail);
  return (
    <div>
      <h1>Selamat Datang di CMS Pendataan Hasil Test PCR</h1>
      <div style={{ margin: 100 }}></div>
      <h2 style={{ fontSize: 30 }}>Summary Jumlah Data</h2>
      {role == "Admin" ? (
        <Grid container spacing={3}>
          <Grid xs={12} sm={4}>
            <Paper
              style={{ margin: 15, backgroundColor: "#3f51b5" }}
              elevation={1}
              square
            >
              <div style={{ padding: 15 }}>
                <h3
                  style={{ textAlign: "center", fontSize: 30, color: "white" }}
                >
                  Jumlah User
                </h3>
                <h1
                  style={{ textAlign: "center", fontSize: 70, color: "white" }}
                >
                  {dataUser ? (
                    <CountUp end={dataUser.length} start={0} duration={1} />
                  ) : (
                    0
                  )}
                </h1>
              </div>
            </Paper>
          </Grid>

          <Grid xs={12} sm={4}>
            <Paper
              style={{ margin: 15, backgroundColor: "#3f51b5" }}
              elevation={1}
              square
            >
              <div style={{ padding: 15 }}>
                <h3
                  style={{ textAlign: "center", fontSize: 30, color: "white" }}
                >
                  Jumlah Barcode
                </h3>
                <h1
                  style={{ textAlign: "center", fontSize: 70, color: "white" }}
                >
                  {dataBarcode ? (
                    <CountUp end={dataBarcode.length} start={0} duration={1} />
                  ) : (
                    0
                  )}
                </h1>
              </div>
            </Paper>
          </Grid>

          <Grid xs={12} sm={4}>
            <Paper
              style={{ margin: 15, backgroundColor: "#3f51b5" }}
              elevation={1}
              square
            >
              <div style={{ padding: 15 }}>
                <h3
                  style={{ textAlign: "center", fontSize: 30, color: "white" }}
                >
                  Jumlah Hasil Ekstrasi
                </h3>
                <h1
                  style={{ textAlign: "center", fontSize: 70, color: "white" }}
                >
                  {dataHasil ? (
                    <CountUp end={dataHasil.length} start={0} duration={1} />
                  ) : (
                    0
                  )}
                </h1>
              </div>
            </Paper>
          </Grid>

          <Grid xs={12} sm={4}>
            <Paper
              style={{ margin: 15, backgroundColor: "#3f51b5" }}
              elevation={1}
              square
            >
              <div style={{ padding: 15 }}>
                <h3
                  style={{ textAlign: "center", fontSize: 30, color: "white" }}
                >
                  Jumlah Hasil Test
                </h3>
                <h1
                  style={{ textAlign: "center", fontSize: 70, color: "white" }}
                >
                  {dataDetail ? (
                    <CountUp end={dataDetail.length} start={0} duration={1} />
                  ) : (
                    0
                  )}
                </h1>
              </div>
            </Paper>
          </Grid>

          {role == "Admin" ? (
            <Grid xs={12} sm={4}>
              <Paper
                style={{ margin: 15, backgroundColor: "#3f51b5" }}
                elevation={1}
                square
              >
                <div style={{ padding: 15 }}>
                  <h3
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      color: "white",
                    }}
                  >
                    Jumlah Instansi Terdaftar
                  </h3>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: 70,
                      color: "white",
                    }}
                  >
                    {dataInstansi ? (
                      <CountUp
                        end={dataInstansi.length}
                        start={0}
                        duration={1}
                      />
                    ) : (
                      0
                    )}
                  </h1>
                </div>
              </Paper>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      ) : (
        <Grid container spacing={3}>
          <Grid xs={12} sm={4}>
            <Paper
              style={{ margin: 15, backgroundColor: "#3f51b5" }}
              elevation={1}
              square
            >
              <div style={{ padding: 15 }}>
                <h3
                  style={{ textAlign: "center", fontSize: 30, color: "white" }}
                >
                  Jumlah User
                </h3>
                <h1
                  style={{ textAlign: "center", fontSize: 70, color: "white" }}
                >
                  {dataUser ? (
                    <CountUp end={userArr.length} start={0} duration={1} />
                  ) : (
                    0
                  )}
                </h1>
              </div>
            </Paper>
          </Grid>

          <Grid xs={12} sm={4}>
            <Paper
              style={{ margin: 15, backgroundColor: "#3f51b5" }}
              elevation={1}
              square
            >
              <div style={{ padding: 15 }}>
                <h3
                  style={{ textAlign: "center", fontSize: 30, color: "white" }}
                >
                  Jumlah Barcode
                </h3>
                <h1
                  style={{ textAlign: "center", fontSize: 70, color: "white" }}
                >
                  {dataBarcode ? (
                    <CountUp end={arrBarcode.length} start={0} duration={1} />
                  ) : (
                    0
                  )}
                </h1>
              </div>
            </Paper>
          </Grid>

          <Grid xs={12} sm={4}>
            <Paper
              style={{ margin: 15, backgroundColor: "#3f51b5" }}
              elevation={1}
              square
            >
              <div style={{ padding: 15 }}>
                <h3
                  style={{ textAlign: "center", fontSize: 30, color: "white" }}
                >
                  Jumlah Hasil Ekstrasi
                </h3>
                <h1
                  style={{ textAlign: "center", fontSize: 70, color: "white" }}
                >
                  {dataHasil ? (
                    <CountUp end={arrHasil.length} start={0} duration={1} />
                  ) : (
                    0
                  )}
                </h1>
              </div>
            </Paper>
          </Grid>

          <Grid xs={12} sm={4}>
            <Paper
              style={{ margin: 15, backgroundColor: "#3f51b5" }}
              elevation={1}
              square
            >
              <div style={{ padding: 15 }}>
                <h3
                  style={{ textAlign: "center", fontSize: 30, color: "white" }}
                >
                  Jumlah Hasil Test
                </h3>
                <h1
                  style={{ textAlign: "center", fontSize: 70, color: "white" }}
                >
                  {dataDetail ? (
                    <CountUp end={arrDetail.length} start={0} duration={1} />
                  ) : (
                    0
                  )}
                </h1>
              </div>
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default Home;
